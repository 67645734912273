// 
// modal.scss
//

// Title text within header
.modal-title {
    margin-top: 0;
}

// Modal full width
.modal-full-width {
    width: 95%;
    max-width: none;
}

// Modal Positions
.modal-top {
    margin: 0 auto;
}

.modal-md {
    max-width: 550px;
}

.modal-bottom-notice {
    position: fixed;
    margin: 0;
    bottom: 5%;
    right: 5%;
    background: white;
    border-radius: 5px;
    width: 350px;
    max-width: 350px;
    .modal-body {
        width: 300px;
        max-width: 300px;
        padding: 1em !important;
    }
}

// Right Modal
.modal-right {
    position: absolute;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    margin: 0;
    background-color: $modal-content-bg;
    align-content: center;
    transform: translate(25%,0) !important;

    button.close {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 1;
    }
}

.modal {
    &.show {
        .modal-right {
            transform: translate(0,0) !important;
        }
    }
}

// Bottom modal
.modal-bottom {
    display: flex;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    margin: 0 auto;
    align-content: center;
}

// Colored modal header
.modal-colored-header {
    color: $white;
    border-radius: 0;

    .close {
        color: $white !important;
    }
}

.bootbox-confirm {
    .modal-header { border-bottom: $input-border-width solid $input-border-color; }
}

.modal-body {
    font-family: $font-family-secondary;
}


// Custom animation modal
.modal-demo {
    background-color: $card-bg;
    width: 600px !important;
    border-radius: 4px;
    display: none;
    position: relative;
    .close {
        position: absolute;
        top: 15px;
        right: 25px;
        color: $close-color;
    }
}

@media (max-width: 768px) {
    .modal-demo {
        width: 96% !important;
    }
}

.custom-modal-title {
    padding: 15px 25px 15px 25px;
    line-height: 22px;
    font-size: 18px;
    color: $card-cap-color;
    background-color: $card-cap-bg;
    text-align: left;
    margin: 0;
}

.custom-modal-text {
    padding: 20px;
}

.custombox-modal-wrapper {
    text-align: left;
}

.custombox-modal-flash,
.custombox-modal-rotatedown {
    .close {
        top: 20px;
        z-index: 9999;
    }
}

.modal-content-dominate {
    .modal-dialog {
        margin: 30px auto;
    }
    .modal-body {
        position:relative;
        padding: 0;
    }
    .btn-close {
        position: absolute;
        right: -30px;
        top: 0;
        opacity: 0.9;
        background-color: white;
        @media(max-width: map-get($grid-breakpoints, "sm")) {
            top: -30px;
            right: 5px;
        }
    }
}

// modal that will appear over an open modal
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2010;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

// used for triggering the signup modal over the review modal.
.modal-backdrop.show:has(+ .modal-over-modal.show) {
    display: block;
    z-index: 2000;
}
.modal-over-modal {
    position: fixed;
    top: 100%; /* Start off-screen at the bottom */
    left: 50%;
    transform: translate(-50%, 0);
    transition: transform 0.5s ease-in-out, top 0.5s ease-in-out;
    z-index: 2010;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;

    &.show {
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .modal-dialog {
        max-width: 550px;
        &.modal-dialog-centered, &.modal-dialog-scrollable {
            height: calc(100% - 1.75rem * 2);
            @media(max-width: map-get($grid-breakpoints, "sm")) {
                height: 100% !important;
            }
        }
    }

    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        color: var(--bs-modal-color);
        pointer-events: auto;
        background-color: #FFFFFF;
        background-clip: padding-box;
        border: 1px solid transparent;
        border-radius: 0.2rem;
        outline: 0;
    }

    .modal-body {
        padding: 1rem;
    }
}
