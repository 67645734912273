//
// forms.scss
//

.form-group { margin-bottom: 1rem; }
.form-control,
.form-select {
    box-shadow: $components-shadow-sm;
    &:disabled {
        background-color: $gray-200;
    }
}

// Form-control light
.form-control-light {
    background-color: $input-light-bg !important;
    border-color: $input-light-bg !important;
}


// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}
.parsley-errors-list {
    top: -30px;
    list-style-type: none;
    padding-left: 0;
    font-size: 0.75rem;
    > * { color: $reddark; }
}

.parsley-error, .field-error {
    border-color: $reddark !important;
}
.parsley-required {
    color: $reddark;
}


// Custom select
.form-select.is-invalid, .form-control.is-invalid,.form-select:invalid, .form-control:invalid,
.form-select.is-valid, .form-control.is-valid, .form-select:valid,.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

.form-select {
    // should match form-control
    padding: $input-padding-y $input-padding-x;
    -moz-padding-start: subtract($input-padding-x, 3px); // See https://github.com/twbs/bootstrap/issues/32636
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;

    &:focus {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        border-color: $input-focus-border-color;
        outline: 0;
    }
}

// Comment box
.comment-area-box {
    .form-control {
        border-color: $gray-300;
        border-radius: $input-border-radius $input-border-radius 0 0;
    }
    .comment-area-btn {
        background-color: $gray-100;
        padding: 10px;
        border: 1px solid $gray-300;
        border-top: none;
        border-radius: 0 0 $input-border-radius $input-border-radius;
    }
}

// Search box 
.search-bar {

    .form-control {
        padding-left: 40px;
        padding-right: 20px;
        border-radius: 30px;
    }

    span {
        position: absolute;
        z-index: 10;
        font-size: 16px;
        line-height: $input-height;
        left: 13px;
        top: -2px;
        color: $text-muted;
    }
}

// Show Password
.password-eye {
    &:before {
        font-family: feather!important;
        content: "\e86a";
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        vertical-align: middle;
        line-height: 1.2;
        font-size: 16px;
    }
}

.show-password {
    .password-eye {
        &:before {
            content: "\e86e";
        }
    }  
}

// needed only for bootstrap 4 - can remove with bootstrap 5
input[type="file"] { display: none; }
.form-control-file {
    display: inline-block;
    cursor: pointer;
    margin-bottom: 0;
}

.form-check {
    @each $color, $value in $theme-colors {
        &-#{$color} {
            & .form-check-input:checked {
                background-color: $value !important;
                border-color: $value !important;
            }
            & .form-check-input:focus {
                box-shadow: none;
            }
        }
    }
}