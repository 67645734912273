/*
Template Name: Ubold - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 4.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/

@import "bootstrap/scss/functions";
@import "variables"; // customized variables
@import "mixins";

//Fonts
@import "fonts";

@import "custom";
@import "layout";

//Components
@import "components/avatar";
@import "components/helper";
@import "components/social";
@import "components/widgets";
@import "components/custom-checkbox";
@import "components/custom-radio";
@import "components/ribbons";
@import "components/print";
@import "components/preloader";
@import "components/slide-sidebar";
@import '../vendor_dashboards/structure/slide-sidebar';

@import '../ubold_theme/plugins/simplebar';

//Pages - as needed
//@import "pages/timeline";
//@import "pages/profile";

//Plugins - as needed
//@import "plugins/autocomplete";
//@import "plugins/parsley";

//Custom bootstrap components override
//@import "gitb_custom/tables";


