//
// slide-sidebar.scss
//

.slide-bar-xs {
    width: 20%;
}

.slide-bar-sm {
    width: 30%;
}

.slide-bar-md {
    width: 45%;
}

.slide-bar-lg {
    width: 60%;
}

.slide-bar-xl {
    width: 75%;
}

.slide-bar-right-xs {
    right: -20%;
}

.slide-bar-right-sm {
    right: -30%;
}

.slide-bar-right-md {
    right: -45%;
}

.slide-bar-right-lg {
     right: -60%;
 }

.slide-bar-right-xl {
    right: -75%;
}

.slide-bar-left-xs {
    left: -20%;
}

.slide-bar-left-sm {
    left: -30%;
}

.slide-bar-left-md {
    left: -45%;
}

.slide-bar-left-lg {
    left: -60%;
}

.slide-bar-left-xl {
    left: -75%;
}

.slide-bar {
    background-color: #ffffff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    display: block;
    position: fixed;
    transition: all 200ms ease-out;
    // width: $rightbar-width;
    z-index: 9999;
    float: right !important;
    // right: -($rightbar-width + 10px);
    top: 0;
    bottom: 0;
    
    .slidebar-title {
        background-color: $primary;
        padding: 27px 25px;
        color: $white;
    }

    .slide-bar-toggle {
        background-color: lighten($dark, 7%);
        height: 24px;
        width: 24px;
        line-height: 27px;
        color: $white;
        text-align: center;
        border-radius: 50%;
        margin-top: -4px;

        &:hover {
            background-color: lighten($dark, 10%);
        }
    }

    .user-box {
        padding: 25px;
        text-align: center;

        .user-img {
            position: relative;
            height: 64px;
            width: 64px;
            margin: 0 auto 15px auto;

            .user-edit {
                position: absolute;
                right: -5px;
                bottom: 0px;
                height: 24px;
                width: 24px;
                background-color: $white;
                line-height: 24px;
                border-radius: 50%;
                box-shadow: $shadow-lg;
            }
        }

        h5 {
            margin-bottom: 2px;

            a {
                color: $dark;
            }
        }
    }

    .notification-item {
        .media {
            padding: 0.75rem 1rem;

            &:hover {
                background-color: $dropdown-link-hover-bg;
            }
        }

        .user-status {
            position: absolute;
            right: 0px;
            bottom: -4px;
            font-size: 10px;

            &.online {
                color: $success;
            }

            &.away {
                color: $warning;
            }

            &.busy {
                color: $danger;
            }
        }
    }
}

// Slidebar overlay
.slidebar-overlay {
    background-color: rgba($dark, 0.2);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 9998;
    transition: all .2s ease-out;
}

[class^="slide-bar-right-"] {
    right: 0;
}

.slide-bar-enabled {

    .slide-bar-right {
        &-xs, &-sm, &-md, &-lg, &-xl {
            right: 0;
        }
    }

    .slide-bar-left {
        &-xs, &-sm, &-md, &-lg, &-xl {
            left: 0;
        }
    }

    .slidebar-overlay {
        display: block;
    }
}

@media(max-width: $mobile-end) {
    .slide-bar {
        overflow: auto;

        .slimscroll-menu {
            height: auto !important;
        }
    }
}

@media(max-width: map-get($grid-breakpoints, "lg")) {
    [class*="slide-bar-"] {
        width: 100%;
    }
    [class*="slide-bar-right-"] {
        right: -100%;
    }
    [class*="slide-bar-left-"] {
        left: -100%;
    }
}

#body {
    display: flex;
    flex-direction: column;
}

.custom-scroller {
    position: relative;
    overflow-y: auto;
  
    /* width */
    &::-webkit-scrollbar {
      max-width: 5px;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: #c5ced6;
      border-radius: 10%;
    }

    &.custom-scroller-transparent {
        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
    }

    &.custom-scroller-show-track {
        &::-webkit-scrollbar-track {
            background-color: #e6eaed;
        }
    }
  
    /* Handle on hover */
    // ::-webkit-scrollbar-thumb:hover {
    //     background-color: #555;
    // }
  
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: #6c757d50;
      }
    }
  }