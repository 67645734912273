//
// nav.scss
//

.nav-tabs {
    > li {
        > a {
            color: $gray-700;
            font-weight: $label-font-weight;
        }
    }
}

.nav-pills {
    @media(max-width: map-get($grid-breakpoints, "md")) {
        overflow-x: auto;
    }
    > a {
        color: $secondary;
        text-decoration: none;
        font-weight: normal;
        &:hover {
            color: $gray-700;
        }
        &.active {
            color: $secondary !important;
            background: none !important;
            border-radius: 0 !important;
            border-bottom: 1px solid $primary-text;
            font-weight: bold;
        }
    }
}


//Navtab custom - redone for peerspot
.navtabs-light {
    .nav-link {
        background-color: white;
        color: $secondary;
    }
}

.navtab-vertical {
    border-top: 1px solid $gray-400;
    border-bottom: 1px solid $gray-400;

    &:not(.navtabs-light) {
        .nav-link {
            background-color: $gray-200;
            color: $gray-700;
        }
    }

    @mixin nav-tab-vertical {
        flex-direction: column;
        .nav-link {
            width: 100%;
            padding: 12px 16px;
            &.active, &:hover { background: $primary; color: $secondary; }
            &.sub-nav-link { padding-left: 24px; }
        }
    }

    &.navtab-vertical-mobile {
        @include nav-tab-vertical;
        @media(max-width: map-get($grid-breakpoints, "md")) {
            .nav-link {
                &:after {
                    position: absolute;
                    right: 20px;
                    font-family: 'Font Awesome 6 Pro';
                    font-size: 15px;
                    font-weight: 300;
                    color: $secondary;
                    content: '\f105';
                }
            }
        }
    }

    &:not(.navtab-vertical-mobile) {
        @media(min-width: map-get($grid-breakpoints, "lg")) {
            @include nav-tab-vertical;
        }
        @media(max-width: map-get($grid-breakpoints, "lg")) {
            flex-direction: row;
            overflow: auto;
            white-space: nowrap;
            flex-wrap: nowrap;
            margin-bottom: 2em;
            .nav-link {
                padding: 12px 14px 8px 14px;
                border-bottom: 4px solid $gray-200 !important;
                &.active, &:hover {
                    background-color: $gray-200;
                    border-color: $primary !important;
                    color: $secondary;
                }
                &.sub-nav-link {
                    padding-top: 14px
                }
            }
        }
    }
    .nav-link {
        margin: 0;
        font-size: 16px;
        border-radius: 0;
        font-weight: 400;
        border: 0;
        text-align: left;
        &.sub-nav-link {
          font-size: 16px;
          padding-left: 24px;
          font-weight: 300;
        }
    }
}


// Nav bordered

.nav-bordered {
    border-bottom: 2px solid rgba($gray-600, 0.2) !important;
    .nav-item {
        margin-bottom: -2px;
    }

    li {
        a {
            border: 0 !important;
            padding: 10px 20px;
        }
    }

a.active {
    border-bottom: 2px solid $primary !important;
}
}

.tab-content {
    padding: 20px 0 0 0;
}
