//
// reboot.scss
//


// Forms
label {
    font-weight: $label-font-weight;
}


button:focus {
    outline: none;
}

fieldset {
    padding: 0 1rem;
    legend {
        float: none;
        width: auto;
        margin: 0;
    }
}