 // Variables
//
// custom-variables
//
 @use "sass:math";


$prefix:  bs- !default;

// Dropdown Large
$dropdown-lg-width:                                 320px;

// Apex chart
$apex-grid-color:                                 #f9f9fd;

// Chat widget
$chat-primary-user-bg:                            #fef5e4;
$chat-secondary-user-bg:                          #f1f3fa;

// Text-color (h1 to h6)
$text-color-h:                                    #343a40;

// Card overlay 
$card-overlay-color:                              #3e4852;
$card-overlay-bg:                                   rgba(#ffffff, 0.8);

// Input light gray
$input-light-bg:                                  #f3f7f9;

// Shadow
$shadow-sm:                                         0 0.75rem 6rem rgba(56, 65, 74,.03);
$shadow:                                            0 0 35px 0 rgba(154, 161, 171, 0.15);
$shadow-lg:                                         0 0 45px 0 rgba(0, 0, 0, 0.12);

// Components Shadows
$components-shadow-sm:                              none;
$components-shadow:                                 none;
$components-shadow-lg:                              none;

// label 
$label-font-weight:                                 700;

//
// Color system
// 

$white:    #fff;
$gray-100: #FAFAFA; //ps
$gray-200: #F5F5F5; //ps
$gray-300: #ededed;
$gray-400: #E5E6E1; // ps
$gray-500: #adb5bd;
$gray-600: #A9A9A9; //ps
$gray-700: #848484; //ps
$gray-800: #666560; //ps
$gray-900: #4D4D4D; //ps

$black:    #08090D;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue:       #36627F;
$blue-hover: #0a4d78;
$blue-link: #2a8ecf;
$brown:      #8B827B;
$purple:     #785CA7;
$indigo:     #785CA7;
$pink:       #E94A7E;
$red:        #FF0000;
$orange:     #FD964C;
$yellow:     #FFD700;
$green:      #70CACA;
$teal:       #70CACA;
$cyan:       #70CACA;
$yellowdark: #FFCC00;
$yellowlight: #FFF7CC;
$reddark: #bc0505;
$pro:   #07C960;
$con: $reddark;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "blue-hover":       $blue-hover,
     "blue-link":       $blue-link,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "reddark":        $reddark,
    "orange":     $orange,
    "yellow":     $yellow,
    "yellowdark":     $yellowdark,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-400,
    "gray-dark":  $gray-700
  ),
  $colors
);

$primary:       $yellow;
$primary-text: $yellowdark;
$primary-soft: $yellowlight;
$secondary:     $black;
$success:       $pro;
$info:          $purple;
$warning:       $orange;
$danger:        $reddark;
$blue:          $blue;
$light:         $gray-200;
$dark:          $gray-900;
$medium:   $gray-700;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "medium":      $medium,
    "dark":       $dark,
    "pro":       $pro,
    "con":       $con
  ),  $theme-colors);

 $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

$twitter-color: #0F1419; // rgb(15, 20, 25)
$twitter-color-reverse: #E5E5E7;
$facebook-color: #4267B2;
$linkedin-color: #0077B5;
$youtube-color: #FF0000;
$whatsapp-color: #25D366;

$social-colors: ();
$social-colors: map-merge(
  (
    "twitter": $twitter-color,
    "x-twitter": $twitter-color,
    "twitter-reverse": $twitter-color-reverse ,
    "facebook": $facebook-color,
    "linkedin": $linkedin-color,
    "youtube": $youtube-color,
    "whatsapp": $whatsapp-color,
  ), $social-colors
);

 // user avatar palette
 $avatar-palette: (
                 (#A97F13, #FFF8E8),
                 (#1B7070, #EBFFFF),
                 (#BD9B13, #FFF7CC),
                 (#093A5A, #E4F4FF),
                 (#B44B00, #FFECDE),
                 (#007A38, #ECFBF3),
                 (#A31300, #F9EFEE),
                 (#3E216F, #F0E7FF),
                 (#674F3D, #EDE3DB),
                 (#B9003C, #FFDFEA)
 );

  // Set a specific jump point for requesting color jumps
$theme-color-interval:      8%;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$color-contrast-dark:             $gray-800;
$color-contrast-light:            $white;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<","%3c"),
  (">","%3e"),
  ("#","%23"),
);

 // fusv-disable
 $blue-100: tint-color($blue, 80%);
 $blue-200: tint-color($blue, 60%);
 $blue-300: tint-color($blue, 40%);
 $blue-400: tint-color($blue, 20%);
 $blue-500: $blue;
 $blue-600: shade-color($blue, 20%);
 $blue-700: shade-color($blue, 40%);
 $blue-800: shade-color($blue, 60%);
 $blue-900: shade-color($blue, 80%);

 $indigo-100: tint-color($indigo, 80%);
 $indigo-200: tint-color($indigo, 60%);
 $indigo-300: tint-color($indigo, 40%);
 $indigo-400: tint-color($indigo, 20%);
 $indigo-500: $indigo;
 $indigo-600: shade-color($indigo, 20%);
 $indigo-700: shade-color($indigo, 40%);
 $indigo-800: shade-color($indigo, 60%);
 $indigo-900: shade-color($indigo, 80%);

 $purple-100: tint-color($purple, 80%);
 $purple-200: tint-color($purple, 60%);
 $purple-300: tint-color($purple, 40%);
 $purple-400: tint-color($purple, 20%);
 $purple-500: $purple;
 $purple-600: shade-color($purple, 20%);
 $purple-700: shade-color($purple, 40%);
 $purple-800: shade-color($purple, 60%);
 $purple-900: shade-color($purple, 80%);

 $pink-100: tint-color($pink, 80%);
 $pink-200: tint-color($pink, 60%);
 $pink-300: tint-color($pink, 40%);
 $pink-400: tint-color($pink, 20%);
 $pink-500: $pink;
 $pink-600: shade-color($pink, 20%);
 $pink-700: shade-color($pink, 40%);
 $pink-800: shade-color($pink, 60%);
 $pink-900: shade-color($pink, 80%);

 $red-100: tint-color($red, 80%);
 $red-200: tint-color($red, 60%);
 $red-300: tint-color($red, 40%);
 $red-400: tint-color($red, 20%);
 $red-500: $red;
 $red-600: shade-color($red, 20%);
 $red-700: shade-color($red, 40%);
 $red-800: shade-color($red, 60%);
 $red-900: shade-color($red, 80%);

 $orange-100: tint-color($orange, 80%);
 $orange-200: tint-color($orange, 60%);
 $orange-300: tint-color($orange, 40%);
 $orange-400: tint-color($orange, 20%);
 $orange-500: $orange;
 $orange-600: shade-color($orange, 20%);
 $orange-700: shade-color($orange, 40%);
 $orange-800: shade-color($orange, 60%);
 $orange-900: shade-color($orange, 80%);

 $yellow-100: tint-color($yellow, 80%);
 $yellow-200: tint-color($yellow, 60%);
 $yellow-300: tint-color($yellow, 40%);
 $yellow-400: tint-color($yellow, 20%);
 $yellow-500: $yellow;
 $yellow-600: shade-color($yellow, 20%);
 $yellow-700: shade-color($yellow, 40%);
 $yellow-800: shade-color($yellow, 60%);
 $yellow-900: shade-color($yellow, 80%);

 $green-100: tint-color($green, 80%);
 $green-200: tint-color($green, 60%);
 $green-300: tint-color($green, 40%);
 $green-400: tint-color($green, 20%);
 $green-500: $green;
 $green-600: shade-color($green, 20%);
 $green-700: shade-color($green, 40%);
 $green-800: shade-color($green, 60%);
 $green-900: shade-color($green, 80%);

 $teal-100: tint-color($teal, 80%);
 $teal-200: tint-color($teal, 60%);
 $teal-300: tint-color($teal, 40%);
 $teal-400: tint-color($teal, 20%);
 $teal-500: $teal;
 $teal-600: shade-color($teal, 20%);
 $teal-700: shade-color($teal, 40%);
 $teal-800: shade-color($teal, 60%);
 $teal-900: shade-color($teal, 80%);

 $cyan-100: tint-color($cyan, 80%);
 $cyan-200: tint-color($cyan, 60%);
 $cyan-300: tint-color($cyan, 40%);
 $cyan-400: tint-color($cyan, 20%);
 $cyan-500: $cyan;
 $cyan-600: shade-color($cyan, 20%);
 $cyan-700: shade-color($cyan, 40%);
 $cyan-800: shade-color($cyan, 60%);
 $cyan-900: shade-color($cyan, 80%);

 $blues: (
         "blue-100": $blue-100,
         "blue-200": $blue-200,
         "blue-300": $blue-300,
         "blue-400": $blue-400,
         "blue-500": $blue-500,
         "blue-600": $blue-600,
         "blue-700": $blue-700,
         "blue-800": $blue-800,
         "blue-900": $blue-900
 );

 $indigos: (
         "indigo-100": $indigo-100,
         "indigo-200": $indigo-200,
         "indigo-300": $indigo-300,
         "indigo-400": $indigo-400,
         "indigo-500": $indigo-500,
         "indigo-600": $indigo-600,
         "indigo-700": $indigo-700,
         "indigo-800": $indigo-800,
         "indigo-900": $indigo-900
 );

 $purples: (
         "purple-100": $purple-100,
         "purple-200": $purple-200,
         "purple-300": $purple-300,
         "purple-400": $purple-400,
         "purple-500": $purple-500,
         "purple-600": $purple-600,
         "purple-700": $purple-700,
         "purple-800": $purple-800,
         "purple-900": $purple-900
 );

 $pinks: (
         "pink-100": $pink-100,
         "pink-200": $pink-200,
         "pink-300": $pink-300,
         "pink-400": $pink-400,
         "pink-500": $pink-500,
         "pink-600": $pink-600,
         "pink-700": $pink-700,
         "pink-800": $pink-800,
         "pink-900": $pink-900
 );

 $reds: (
         "red-100": $red-100,
         "red-200": $red-200,
         "red-300": $red-300,
         "red-400": $red-400,
         "red-500": $red-500,
         "red-600": $red-600,
         "red-700": $red-700,
         "red-800": $red-800,
         "red-900": $red-900
 );

 $oranges: (
         "orange-100": $orange-100,
         "orange-200": $orange-200,
         "orange-300": $orange-300,
         "orange-400": $orange-400,
         "orange-500": $orange-500,
         "orange-600": $orange-600,
         "orange-700": $orange-700,
         "orange-800": $orange-800,
         "orange-900": $orange-900
 );

 $yellows: (
         "yellow-100": $yellow-100,
         "yellow-200": $yellow-200,
         "yellow-300": $yellow-300,
         "yellow-400": $yellow-400,
         "yellow-500": $yellow-500,
         "yellow-600": $yellow-600,
         "yellow-700": $yellow-700,
         "yellow-800": $yellow-800,
         "yellow-900": $yellow-900
 );

 $greens: (
         "green-100": $green-100,
         "green-200": $green-200,
         "green-300": $green-300,
         "green-400": $green-400,
         "green-500": $green-500,
         "green-600": $green-600,
         "green-700": $green-700,
         "green-800": $green-800,
         "green-900": $green-900
 );

 $teals: (
         "teal-100": $teal-100,
         "teal-200": $teal-200,
         "teal-300": $teal-300,
         "teal-400": $teal-400,
         "teal-500": $teal-500,
         "teal-600": $teal-600,
         "teal-700": $teal-700,
         "teal-800": $teal-800,
         "teal-900": $teal-900
 );

 $cyans: (
         "cyan-100": $cyan-100,
         "cyan-200": $cyan-200,
         "cyan-300": $cyan-300,
         "cyan-400": $cyan-400,
         "cyan-500": $cyan-500,
         "cyan-600": $cyan-600,
         "cyan-700": $cyan-700,
         "cyan-800": $cyan-800,
         "cyan-900": $cyan-900
 );

 // scss-docs-start theme-text-variables
 $primary-text-emphasis:   shade-color($primary, 60%);
 $secondary-text-emphasis: shade-color($secondary, 60%);
 $success-text-emphasis:   shade-color($success, 60%);
 $info-text-emphasis:      shade-color($info, 60%);
 $warning-text-emphasis:   shade-color($warning, 60%);
 $danger-text-emphasis:    shade-color($danger, 60%);
 $light-text-emphasis:     $gray-700;
 $dark-text-emphasis:      $gray-700;
 // scss-docs-end theme-text-variables

 // scss-docs-start theme-bg-subtle-variables
 $primary-bg-subtle:       tint-color($primary, 80%);
 $secondary-bg-subtle:     tint-color($secondary, 80%);
 $success-bg-subtle:       tint-color($success, 80%);
 $info-bg-subtle:          tint-color($info, 80%);
 $warning-bg-subtle:       tint-color($warning, 80%);
 $danger-bg-subtle:        tint-color($danger, 80%);
 $light-bg-subtle:         mix($gray-100, $white);
 $dark-bg-subtle:          $gray-400;
 // scss-docs-end theme-bg-subtle-variables

 // scss-docs-start theme-border-subtle-variables
 $primary-border-subtle:   tint-color($primary, 60%);
 $secondary-border-subtle: tint-color($secondary, 60%);
 $success-border-subtle:   tint-color($success, 60%);
 $info-border-subtle:      tint-color($info, 60%);
 $warning-border-subtle:   tint-color($warning, 60%);
 $danger-border-subtle:    tint-color($danger, 60%);
 $light-border-subtle:     $gray-200;
 $dark-border-subtle:      $gray-500;
 // scss-docs-end theme-border-subtle-variables


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                                false;
$enable-rounded:                              true;
$enable-shadows:                              false;
$enable-gradients:                            false;
$enable-transitions:                          true;
 $enable-reduced-motion:   true;
$enable-hover-media-query:                    false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true;
 $enable-button-pointers:           true;
//$enable-print-styles:                         true;
$enable-responsive-font-sizes:                false;
$enable-validation-icons:                     true;
$enable-deprecation-messages:                 true;
 $enable-dark-mode:            true !default;
 $color-mode-type:             data !default; // `data` or `media-query`

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1.5rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3)
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    20: 20%,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);


// Body
//
// Settings for the `<body>` element.

$body-bg:                   #f5f6f8;
$body-color:                $gray-700;


// Links
//
// Style anchor elements.

$link-color:                              $primary;
$link-decoration:                         none;
$link-hover-color:                        darken($link-color, 15%);
$link-hover-decoration:                   none;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$mobile-end: 812px;
$tablet-end: 1300px;
$desktop-start: $mobile-end + 1;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 813px,
  lg: 992px,
  xl: 1367px,
  xxl: 1400px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           24px;
$grid-row-columns:            6;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              2;
$line-height-sm:              1.5;

$border-width:                1px;
$border-color:                lighten($gray-300,2.5%);

$border-radius:               .25rem;
$border-radius-lg:            .3rem;
$border-radius-sm:            .2rem;

$rounded-pill:                50rem;

$box-shadow-sm:             0 0.75rem 6rem rgba(56, 65, 74,.03);
$box-shadow:                0 0 35px 0 rgba(154, 161, 171, 0.15);
$box-shadow-lg:             0 0 45px 0 rgba(0, 0, 0, 0.12);
$box-shadow-bottom:     0 10px 10px -5px rgba(0, 0, 0, 0.12);

$component-active-color:      $white;
$component-active-bg:         $primary;

$caret-width:                 .3em;
$caret-vertical-align:        $caret-width * .85;
$caret-spacing:               $caret-width * .85;

$transition-base:             all .2s ease-in-out;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;

 // scss-docs-start focus-ring-variables
 $focus-ring-blur:       0 !default;
 $focus-ring-opacity:    .25 !default;
 $focus-ring-width:      .25rem !default;
 $focus-ring-color:      rgba($primary, $focus-ring-opacity);
 $focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color;

//$aspect-ratios: ();
//// stylelint-disable-next-line scss/dollar-variable-default
//$aspect-ratios: join(
//  (
//    (21 9),
//    (16 9),
//    (4 3),
//    (1 1),
//  ),
//  $aspect-ratios
//);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

 // fonts
$font-family: 'Martel', serif;
$font-family-secondary: 'Lato', sans-serif;
$font-family-sans-serif: $font-family-secondary;
$font-family-monospace:  $font-family;
$font-family-base: $font-family-secondary;


$font-size-base:              0.875rem;
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .9;

 // Font weight
$font-weight-lighter:         lighter;
$font-weight-light:            300;
$font-weight-normal:        400;
$font-weight-medium:     500;
$font-weight-semibold:    600;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$h1-font-size:                2.5rem;
$h2-font-size:                2rem;
$h3-font-size:                1.75rem;
$h4-font-size:                1.5rem;
$h5-font-size:                1.25rem;
$h6-font-size:                1rem;

 $font-sizes: (
         1: $h1-font-size,
         2: $h2-font-size,
         3: $h3-font-size,
         4: $h4-font-size,
         5: $h5-font-size,
         6: $h6-font-size,
         7: $font-size-base * 0.9
 ) !default;


$headings-margin-bottom:      $spacer;
$headings-font-family:        null;
$headings-font-weight:        400;
$headings-line-height:        1.1;
$headings-color:              null;

$display1-size:               6rem;
$display2-size:               5.5rem;
$display3-size:               4.5rem;
$display4-size:               3.5rem;

$display1-weight:             300;
$display2-weight:             300;
$display3-weight:             300;
$display4-weight:             300;
$display-line-height:         $headings-line-height;

$lead-font-size:              $font-size-base * 1.25;
$lead-font-weight:            300;

$small-font-size:             0.75rem;

$text-muted:                  $gray-600;

$blockquote-small-color:      $gray-600;
$blockquote-small-font-size:  $small-font-size;
$blockquote-font-size:        $font-size-base * 1.25;

$hr-border-color:             lighten($gray-300,2.5%);
$hr-border-width:             $border-width;

$mark-padding:                .2em;

$dt-font-weight:              $font-weight-bold;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25);
$nested-kbd-font-weight:      $font-weight-bold;

$list-inline-padding:         6px;

$mark-bg:                     #fcf8e3;

$hr-margin-y:                 $spacer;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .85rem;
$table-cell-padding-sm:       .5rem;

$table-color:                 $body-color;
$table-bg:                    transparent;
$table-accent-bg:             transparent;
$table-hover-color:           $table-color;
$table-hover-bg:              $gray-100;
$table-active-bg:             $table-hover-bg;

$table-border-width:          $border-width;
$table-border-color:          $gray-300;

$table-head-bg:               $gray-100;
$table-head-color:            $gray-700;

$table-dark-color:            $gray-600;
$table-dark-bg:               $gray-900;
$table-dark-accent-bg:        rgba($white, .05);
$table-dark-hover-color:      $table-dark-color;
$table-dark-hover-bg:         rgba($white, .075);
$table-dark-border-color:     lighten($gray-900, 7.5%);

$table-striped-order:         odd;
$table-striped-bg:            $gray-100;

$table-caption-color:         $text-muted;

$table-bg-level:              -9;
$table-border-level:          -6;

$table-bg-scale:              -80%;
// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
       "primary":    shift-color($primary, $table-bg-scale),
       "secondary":  shift-color($secondary, $table-bg-scale),
       "success":    shift-color($success, $table-bg-scale),
       "info":       shift-color($info, $table-bg-scale),
       "warning":    shift-color($warning, $table-bg-scale),
       "danger":     shift-color($danger, $table-bg-scale),
       "light":      $light,
       "dark":       $dark,
);


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .45rem;
$input-btn-padding-x:         .90rem;
$input-btn-font-family:       null;
$input-btn-font-size:         $font-size-base;
$input-btn-line-height:       $line-height-base;

$input-btn-focus-width:       .15rem;
$input-btn-focus-color:       rgba($component-active-bg, .25);
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm:      .28rem;
$input-btn-padding-x-sm:      .8rem;
$input-btn-font-size-sm:      $font-size-sm;
$input-btn-line-height-sm:    $line-height-sm;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size-lg:      $font-size-lg;
$input-btn-line-height-lg:    $line-height-lg;

$input-btn-border-width:      $border-width;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-color:                   var(--#{$prefix}body-color) !default;
$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x;
$btn-font-family:             $input-btn-font-family;
$btn-font-size:               $input-btn-font-size;
$btn-line-height:             $input-btn-line-height;
$btn-white-space:             null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            $input-btn-padding-y-sm;
$btn-padding-x-sm:            $input-btn-padding-x-sm;
$btn-font-size-sm:            $input-btn-font-size-sm;
$btn-line-height-sm:          $input-btn-line-height-sm;

$btn-padding-y-lg:            $input-btn-padding-y-lg;
$btn-padding-x-lg:            $input-btn-padding-x-lg;
$btn-font-size-lg:            $input-btn-font-size-lg;
$btn-line-height-lg:          $input-btn-line-height-lg;

$btn-border-width:            $input-btn-border-width;

$btn-font-weight:             $font-weight-normal;
$btn-box-shadow:              0 0 0;
$btn-focus-width:             $input-btn-focus-width;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow;
$btn-disabled-opacity:        .65;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125);

$btn-link-disabled-color:     $gray-600;
 $btn-link-color:              $blue-link;
 $btn-link-hover-color:        $blue-hover;
 $btn-link-disabled-color:     $gray-600 !default;
 $btn-link-focus-shadow-rgb:   $gray-400;

$btn-block-spacing-y:         .5rem;
 $btn-close-width:            1em !default;
 $btn-close-height:           $btn-close-width !default;
 $btn-close-padding-x:        .25em !default;
 $btn-close-padding-y:        $btn-close-padding-x !default;
 $btn-close-opacity:          .5 !default;
 $btn-close-color:            $black !default;
 $btn-close-hover-opacity:    .75 !default;
 $btn-close-focus-opacity:    1 !default;
 $btn-close-disabled-opacity: .25 !default;
 $btn-close-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>") !default;
 $btn-close-focus-shadow:     $focus-ring-box-shadow !default;
 $btn-close-white-filter:     invert(1) grayscale(100%) brightness(200%) !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           .15rem;
$btn-border-radius-lg:        $border-radius-lg;
$btn-border-radius-sm:        $border-radius-sm;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
 $btn-hover-bg-shade-amount:       15% !default;
 $btn-hover-bg-tint-amount:        15% !default;
 $btn-hover-border-shade-amount:   20% !default;
 $btn-hover-border-tint-amount:    10% !default;
 $btn-active-bg-shade-amount:      20% !default;
 $btn-active-bg-tint-amount:       20% !default;
 $btn-active-border-shade-amount:  25% !default;
 $btn-active-border-tint-amount:   10% !default;

// Forms

$label-margin-bottom:                   .5rem;

$input-padding-y:                       $input-btn-padding-y;
$input-padding-x:                       $input-btn-padding-x;
$input-font-family:                     $input-btn-font-family;
$input-font-size:                       $input-btn-font-size;
$input-font-weight:                     $font-weight-base;
$input-line-height:                     $input-btn-line-height;

$input-padding-y-sm:                    $input-btn-padding-y-sm;
$input-padding-x-sm:                    $input-btn-padding-x-sm;
$input-font-size-sm:                    $input-btn-font-size-sm;
$input-line-height-sm:                  $input-btn-line-height-sm;

$input-padding-y-lg:                    $input-btn-padding-y-lg;
$input-padding-x-lg:                    $input-btn-padding-x-lg;
$input-font-size-lg:                    $input-btn-font-size-lg;
$input-line-height-lg:                  $input-btn-line-height-lg;

$input-bg:                              $white;
$input-disabled-bg:                     $white;

$input-color:                           $gray-700;
$input-border-color:                    $gray-400;
$input-border-width:                    $input-btn-border-width;
$input-box-shadow:                      inset 0 1px 1px rgba($black, .075);

$input-border-radius:                   0.2rem;
$input-border-radius-lg:                $border-radius-lg;
$input-border-radius-sm:                $border-radius-sm;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              darken($input-border-color,10%);
$input-focus-color:                     $input-color;
$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                none;

$input-placeholder-color:               $gray-500;
$input-plaintext-color:                 $body-color;

$input-height-border:                   $input-border-width * 2;

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y);
$input-height-inner-quarter:            add($input-line-height * .25em, math.div($input-padding-y, 2));

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
$input-height-sm:                       add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg:                       add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false));

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-text-margin-top:                  .25rem;

$form-check-input-gutter:               1.25rem;
$form-check-input-margin-y:             .3rem;
$form-check-input-margin-x:             .25rem;
$form-check-input-bg:                   $input-bg;
$form-check-input-border:               1px solid $gray-400;

$form-check-inline-margin-x:            .75rem;
$form-check-inline-input-margin-x:      .3125rem;

$form-grid-gutter-width:                10px;
$form-group-margin-bottom:              1rem;

$input-group-addon-color:               $input-color;
$input-group-addon-bg:                  $gray-200;
$input-group-addon-border-color:        $input-border-color;

$custom-forms-transition:               background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$custom-control-gutter:                 .5rem;
$custom-control-spacer-x:               1rem;
$custom-control-cursor:                 null;

$custom-control-indicator-size:         1rem;
$custom-control-indicator-bg:           $input-bg;

$custom-control-indicator-bg-size:      50% 50%;
$custom-control-indicator-box-shadow:   $input-box-shadow;
$custom-control-indicator-border-color: $gray-500;
$custom-control-indicator-border-width: $input-border-width;

$custom-control-label-color:            null;

$custom-control-indicator-disabled-bg:          $input-disabled-bg;
$custom-control-label-disabled-color:           $gray-600;

$custom-control-indicator-checked-color:        $component-active-color;
$custom-control-indicator-checked-bg:           $component-active-bg;
$custom-control-indicator-checked-disabled-bg:  rgba($primary, .5);
$custom-control-indicator-checked-box-shadow:   none;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow;
$custom-control-indicator-focus-border-color:   $input-focus-border-color;

$custom-control-indicator-active-color:         $component-active-color;
$custom-control-indicator-active-bg:            lighten($component-active-bg, 35%);
$custom-control-indicator-active-box-shadow:    none;
$custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg;

$custom-checkbox-indicator-border-radius:       $border-radius;
$custom-checkbox-indicator-icon-checked:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>");

$custom-checkbox-indicator-indeterminate-bg:           $component-active-bg;
$custom-checkbox-indicator-indeterminate-color:        $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>");
$custom-checkbox-indicator-indeterminate-box-shadow:   none;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg;

$custom-radio-indicator-border-radius:          50%;
$custom-radio-indicator-icon-checked:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>");

$custom-switch-width:                           $custom-control-indicator-size * 1.75;
$custom-switch-indicator-border-radius:         math.div($custom-control-indicator-size, 2);
$custom-switch-indicator-size:                  subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4);

$custom-select-padding-y:           $input-padding-y;
$custom-select-padding-x:           $input-padding-x;
$custom-select-font-family:         $input-font-family;
$custom-select-font-size:           $input-font-size;
$custom-select-height:              $input-height;
$custom-select-indicator-padding:   1rem; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight:         $input-font-weight;
$custom-select-line-height:         $input-line-height;
$custom-select-color:               $input-color;
$custom-select-disabled-color:      $gray-600;
$custom-select-bg:                  $input-bg;
$custom-select-disabled-bg:         $gray-200;
$custom-select-bg-size:             8px 10px; // In pixels because image dimensions
$custom-select-indicator-color:     $gray-800;
$custom-select-indicator:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>");
$custom-select-background:          escape-svg($custom-select-indicator) no-repeat right $custom-select-padding-x center / $custom-select-bg-size; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right: add(1em * .75, (2 * $custom-select-padding-y * .75) + $custom-select-padding-x + $custom-select-indicator-padding);
$custom-select-feedback-icon-position:      center right ($custom-select-padding-x + $custom-select-indicator-padding);
$custom-select-feedback-icon-size:          $input-height-inner-half $input-height-inner-half;

$custom-select-border-width:        $input-border-width;
$custom-select-border-color:        $input-border-color;
$custom-select-border-radius:       $border-radius;
$custom-select-box-shadow:          inset 0 1px 2px rgba($black, .075);

$custom-select-focus-border-color:  $input-focus-border-color;
$custom-select-focus-width:         $input-focus-width;
$custom-select-focus-box-shadow:    none;

$custom-select-padding-y-sm:        $input-padding-y-sm;
$custom-select-padding-x-sm:        $input-padding-x-sm;
$custom-select-font-size-sm:        $input-font-size-sm;
$custom-select-height-sm:           $input-height-sm;

$custom-select-padding-y-lg:        $input-padding-y-lg;
$custom-select-padding-x-lg:        $input-padding-x-lg;
$custom-select-font-size-lg:        $input-font-size-lg;
$custom-select-height-lg:           $input-height-lg;

$custom-range-track-width:          100%;
$custom-range-track-height:         .5rem;
$custom-range-track-cursor:         pointer;
$custom-range-track-bg:             $gray-300;
$custom-range-track-border-radius:  1rem;
$custom-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1);

$custom-range-thumb-width:                   1rem;
$custom-range-thumb-height:                  $custom-range-thumb-width;
$custom-range-thumb-bg:                      $component-active-bg;
$custom-range-thumb-border:                  0;
$custom-range-thumb-border-radius:           1rem;
$custom-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1);
$custom-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width:  $input-focus-width; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg:               lighten($component-active-bg, 35%);
$custom-range-thumb-disabled-bg:             $gray-500;

$custom-file-height:                $input-height;
$custom-file-height-inner:          $input-height-inner;
$custom-file-focus-border-color:    $input-focus-border-color;
$custom-file-focus-box-shadow:      none;
$custom-file-disabled-bg:           $input-disabled-bg;

$custom-file-padding-y:             $input-padding-y;
$custom-file-padding-x:             $input-padding-x;
$custom-file-line-height:           $input-line-height;
$custom-file-font-family:           $input-font-family;
$custom-file-font-weight:           $input-font-weight;
$custom-file-color:                 $input-color;
$custom-file-bg:                    $input-bg;
$custom-file-border-width:          $input-border-width;
$custom-file-border-color:          $input-border-color;
$custom-file-border-radius:         $input-border-radius;
$custom-file-box-shadow:            none;
$custom-file-button-color:          $custom-file-color;
$custom-file-button-bg:             $input-group-addon-bg;
$custom-file-text: (
  en: "Browse"
);


// Form validation

$form-feedback-margin-top:          $form-text-margin-top;
$form-feedback-font-size:           $small-font-size;
$form-feedback-valid-color:         $success;
$form-feedback-invalid-color:       $danger;

$form-feedback-icon-valid-color:    $form-feedback-valid-color;
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color;
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");

$form-validation-states: ();
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
  (
    "valid": (
      "color": $form-feedback-valid-color,
      "icon": $form-feedback-icon-valid
    ),
    "invalid": (
      "color": $form-feedback-invalid-color,
      "icon": $form-feedback-icon-invalid
    ),
  ),
  $form-validation-states
);

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown:                   1000;
$zindex-sticky:                     1020;
$zindex-fixed:                      1030;
$zindex-modal-backdrop:             1080;
$zindex-modal:                      1090;
$zindex-popover:                    1060;
$zindex-tooltip:                    1070;


// Navs

$nav-link-padding-y:                .5rem;
$nav-link-padding-x:                1rem;
$nav-link-disabled-color:           $gray-600;

$nav-tabs-border-color:             $gray-300;
$nav-tabs-border-width:             $border-width;
$nav-tabs-border-radius:            $border-radius;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color;
$nav-tabs-link-active-color:        $gray-700;
$nav-tabs-link-active-bg:           $white;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg;

$nav-pills-border-radius:           $border-radius;
$nav-pills-link-active-color:       $component-active-color;
$nav-pills-link-active-bg:          $component-active-bg;

$nav-divider-color:                 $gray-200;
$nav-divider-margin-y:              math.div($spacer, 3);


// Navbar

$navbar-padding-y:                  math.div($spacer, 2);
$navbar-padding-x:                  $spacer;

$navbar-nav-link-padding-x:         .5rem;

$navbar-brand-font-size:            $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y:            math.div(($nav-link-height - $navbar-brand-height), 2);

$navbar-toggler-padding-y:          .25rem;
$navbar-toggler-padding-x:          .75rem;
$navbar-toggler-font-size:          $font-size-lg;
$navbar-toggler-border-radius:      $btn-border-radius;

$navbar-dark-color:                 rgba($white, .5);
$navbar-dark-hover-color:           rgba($white, .75);
$navbar-dark-active-color:          $white;
$navbar-dark-disabled-color:        rgba($white, .25);
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color:  rgba($white, .1);

$navbar-light-color:                rgba($black, .5);
$navbar-light-hover-color:          rgba($black, .7);
$navbar-light-active-color:         rgba($black, .9);
$navbar-light-disabled-color:       rgba($black, .3);
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgba($black, .1);

$navbar-light-brand-color:                $navbar-light-active-color;
$navbar-light-brand-hover-color:          $navbar-light-active-color;
$navbar-dark-brand-color:                 $navbar-dark-active-color;
$navbar-dark-brand-hover-color:           $navbar-dark-active-color;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                10rem;
$dropdown-padding-y:                .25rem;
$dropdown-spacer:                   .125rem;
$dropdown-font-size:                $font-size-base;
$dropdown-color:                    $body-color;
$dropdown-bg:                       $white;
$dropdown-border-color:             darken($light, 3%);
$dropdown-border-radius:            $border-radius;
$dropdown-border-width:             $border-width;
$dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width);
$dropdown-divider-bg:               $gray-200;
$dropdown-divider-margin-y:         $nav-divider-margin-y;
$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175);

$dropdown-link-color:               $body-color;
$dropdown-link-hover-color:         $headings-color;
$dropdown-link-hover-bg:            $gray-100;

$dropdown-link-active-color:        $dark;
$dropdown-link-active-bg:           $gray-100;
$dropdown-link-disabled-color:      $gray-600;

$dropdown-item-padding-y:           .375rem;
$dropdown-item-padding-x:           1.2rem;

$dropdown-header-color:             inherit;


// Pagination

$pagination-padding-y:              .5rem;
$pagination-padding-x:              .75rem;
$pagination-padding-y-sm:           .25rem;
$pagination-padding-x-sm:           .5rem;
$pagination-padding-y-lg:           .75rem;
$pagination-padding-x-lg:           1.5rem;
$pagination-line-height:            1.25;

$pagination-color:                  $dark;
$pagination-bg:                     $white;
$pagination-border-width:           $border-width;
$pagination-border-color:           $gray-300;

$pagination-focus-box-shadow:       $input-btn-focus-box-shadow;
$pagination-focus-outline:          0;

$pagination-hover-color:            $dark;
$pagination-hover-bg:               $gray-200;
$pagination-hover-border-color:     $gray-300;

$pagination-active-color:           $component-active-color;
$pagination-active-bg:              $component-active-bg;
$pagination-active-border-color:    $pagination-active-bg;

$pagination-disabled-color:         $gray-600;
$pagination-disabled-bg:            $white;
$pagination-disabled-border-color:  $gray-300;


// Jumbotron

$jumbotron-padding:                 2rem;
$jumbotron-color:                   null;
$jumbotron-bg:                      lighten($gray-300,5%);


// Cards

$card-spacer-y:                     1rem;
$card-spacer-x:                     1.5rem;
$card-border-width:                 0;
$card-border-radius:                .15rem;
$card-border-color:                 $gray-400;
$card-inner-border-radius:          subtract($card-border-radius, $card-border-width);
$card-cap-bg:                       lighten($gray-300, 5%);
$card-cap-color:                    null;
$card-height:                       null;
$card-color:                        null;
$card-bg:                           $white;

$card-img-overlay-padding:          1.25rem;

$card-group-margin:                 math.div($grid-gutter-width, 2);
$card-deck-margin:                  $card-group-margin;

$card-columns-count:                3;
$card-columns-gap:                  1.25rem;
$card-columns-margin:               1.25rem;


// Tooltips

$tooltip-font-size:                 $font-size-base;
$tooltip-max-width:                 200px;
$tooltip-color:                     $white;
$tooltip-bg:                        $black;
$tooltip-border-radius:             0.2rem;
$tooltip-opacity:                   .9;
$tooltip-padding-y:                 .4rem;
$tooltip-padding-x:                 .8rem;

$tooltip-margin:                    0;

$tooltip-arrow-width:               .8rem;
$tooltip-arrow-height:              .4rem;
$tooltip-arrow-color:               $tooltip-bg;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y:     $tooltip-padding-y;
$form-feedback-tooltip-padding-x:     $tooltip-padding-x;
$form-feedback-tooltip-font-size:     $tooltip-font-size;
$form-feedback-tooltip-line-height:   $line-height-base;
$form-feedback-tooltip-opacity:       $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;


// Popovers

$popover-font-size:                 $font-size-sm;
$popover-bg:                        #EEF8FF;
$popover-max-width:                 276px;
$popover-border-width:              $border-width;
$popover-border-color:              $gray-300;
$popover-border-radius:             $border-radius;
$popover-inner-border-radius:       subtract($popover-border-radius, $popover-border-width);
$popover-box-shadow:                0 .25rem .5rem rgba($black, .2);

$popover-header-bg:                 $gray-100;
$popover-header-color:              $headings-color;
$popover-header-padding-y:          .7rem;
$popover-header-padding-x:          .8rem;

$popover-body-color:                     $secondary;
$popover-body-padding-y:            $popover-header-padding-y;
$popover-body-padding-x:            $popover-header-padding-x;

$popover-arrow-width:               1rem;
$popover-arrow-height:              .5rem;
$popover-arrow-color:               $popover-bg;

$popover-arrow-outer-color:         fade-in($popover-border-color, .05);


// Toasts

$toast-max-width:                   350px;
$toast-padding-x:                   .75rem;
$toast-padding-y:                   .25rem;
$toast-font-size:                   .875rem;
$toast-color:                       null;
$toast-background-color:            rgba($white, .85);
$toast-border-width:                1px;
$toast-border-color:                rgba(0, 0, 0, .1);
$toast-border-radius:               .25rem;
$toast-box-shadow:                  0 .25rem .75rem rgba($black, .1);

$toast-header-color:                $gray-600;
$toast-header-background-color:     rgba($white, .85);
$toast-header-border-color:         rgba(0, 0, 0, .05);


// Badges

$badge-font-size:                   75%;
$badge-font-weight:                 $font-weight-bold;
$badge-padding-y:                   .25em;
$badge-padding-x:                   .4em;
$badge-border-radius:               $border-radius;

$badge-transition:                  $btn-transition;
$badge-focus-width:                 $input-btn-focus-width;

$badge-pill-padding-x:              .6em;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius:          10rem;


// Modals

// Padding applied to the modal body
$modal-inner-padding:               1rem;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
 $modal-footer-bg:                   null !default;
$modal-footer-margin-between:       .5rem;

$modal-dialog-margin:               .5rem;
$modal-dialog-margin-y-sm-up:       1.75rem;

$modal-title-line-height:           $line-height-base;

$modal-content-color:               null;
$modal-content-bg:                  $white;
$modal-content-border-color:        transparent;
$modal-content-border-width:        1px;
$modal-content-border-radius:       0.2rem;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);
$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5);
$modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5);

$modal-backdrop-bg:                 $gray-900;
$modal-backdrop-opacity:            .5;
$modal-header-border-color:         $border-color;
$modal-footer-border-color:         $modal-header-border-color;
$modal-header-border-width:         $modal-content-border-width;
$modal-footer-border-width:         $modal-header-border-width;
$modal-header-padding-y:            1rem;
$modal-header-padding-x:            1rem;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-xl:                          1140px;
$modal-lg:                          800px;
$modal-md:                          500px;
$modal-sm:                          300px;

$modal-fade-transform:              translate(0, -50px);
$modal-show-transform:              none;
$modal-transition:                  transform .3s ease-out;
$modal-scale-transform:             scale(1.02);


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                   .75rem;
$alert-padding-x:                   1.25rem;
$alert-margin-bottom:               1rem;
$alert-border-radius:               $border-radius;
$alert-link-font-weight:            $font-weight-bold;
$alert-border-width:                $border-width;
$alert-link-color:                  $blue-link;

$alert-bg-level:                    -10;
$alert-border-level:                -9;
$alert-color-level:                 6;

// Accordions
$accordion-color: $secondary;
$accordion-bg: $white;
$accordion-button-active-bg: $primary;
$accordion-button-active-color: $secondary;
$accordion-button-color: $secondary;


// Progress bars

$progress-height:                   0.75rem;
$progress-font-size:                $font-size-base * .75;
$progress-bg:                       lighten($gray-300, 5.5%);
$progress-border-radius:            $border-radius;
$progress-box-shadow:               inset 0 .1rem .1rem rgba($black, .1);
$progress-bar-color:                $white;
$progress-bar-bg:                   $primary;
$progress-bar-animation-timing:     1s linear infinite;
$progress-bar-transition:           width .6s ease;


// List group

$list-group-color:                  null;
$list-group-bg:                     $white;
$list-group-border-color:           rgba($black, .125);
$list-group-border-width:           $border-width;
$list-group-border-radius:          $border-radius;

$list-group-item-padding-y:         .75rem;
$list-group-item-padding-x:         1.25rem;

$list-group-hover-bg:               $gray-100;
$list-group-active-color:           $component-active-color;
$list-group-active-bg:              $component-active-bg;
$list-group-active-border-color:    $list-group-active-bg;

$list-group-disabled-color:         $gray-600;
$list-group-disabled-bg:            $list-group-bg;

$list-group-action-color:           $gray-700;
$list-group-action-hover-color:     $list-group-action-color;

$list-group-action-active-color:    $body-color;
$list-group-action-active-bg:       $gray-200;


// Image thumbnails

$thumbnail-padding:                 .25rem;
$thumbnail-bg:                      $body-bg;
$thumbnail-border-width:            $border-width;
$thumbnail-border-color:            $gray-300;
$thumbnail-border-radius:           $border-radius;
$thumbnail-box-shadow:              0 1px 2px rgba($black, .075);


// Figures

$figure-caption-font-size:          90%;
$figure-caption-color:              $gray-600;


// Breadcrumbs

$breadcrumb-font-size:              null;

$breadcrumb-padding-y:              math.div($spacer, 1.5);
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding:           .5rem;

$breadcrumb-margin-bottom:          1rem;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $gray-400;
$breadcrumb-active-color:           $gray-500;
$breadcrumb-divider:                quote("\F0142");

$breadcrumb-border-radius:          $border-radius;


// Carousel

$carousel-control-color:             $white;
$carousel-control-width:             15%;
$carousel-control-opacity:           .5;
$carousel-control-hover-opacity:     .9;
$carousel-control-transition:        opacity .15s ease;

$carousel-indicator-width:           30px;
$carousel-indicator-height:          3px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer:          3px;
$carousel-indicator-active-bg:       $white;
$carousel-indicator-transition:      opacity .6s ease;

$carousel-caption-width:             70%;
$carousel-caption-color:             $white;

$carousel-control-icon-width:        20px;

$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>");
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>");

$carousel-transition-duration:       .6s;
$carousel-transition:                transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

 $min-contrast-ratio:   4.5 !default;
// Spinners

$spinner-width:         2rem;
$spinner-height:        $spinner-width;
$spinner-border-width:  .25em;

$spinner-width-sm:        1rem;
$spinner-height-sm:       $spinner-width-sm;
$spinner-border-width-sm: .2em;


// Close

$close-font-size:                   1.4rem;
$close-font-weight:                 $font-weight-bold;
$close-color:                       $black;
$close-text-shadow:                 none;


// Code

$code-font-size:                    87.5%;
$code-color:                        $pink;

$kbd-padding-y:                     .2rem;
$kbd-padding-x:                     .4rem;
$kbd-font-size:                     $code-font-size;
$kbd-color:                         $white;
$kbd-bg:                            $gray-900;

$pre-color:                         $gray-900;
$pre-scrollable-max-height:         340px;


// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;
$overflows: auto, hidden;
$positions: static, relative, absolute, fixed, sticky;
 $position-values: (
         0: 0,
         5: 5%,
         10: 10%,
         25: 25%,
         50: 50%,
         75: 75%,
         100: 100%
 );


// Printing

$print-page-size:                   a3;
$print-body-min-width:              map-get($grid-breakpoints, "lg");
