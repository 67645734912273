// 
// buttons.scss
//

// Button shadow

@mixin button-shadow($color) {
    box-shadow: $btn-box-shadow rgba($color, 0.5);
}

// Button Shadows
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-shadow($value);
        &:hover {
            background-color: darken($value, 5%);
        }
    }
}

.btn {
    .mdi {
        &:before {
            margin-top: -1px;
        }
    }

    i {
        display: inline-block;
    }

    &-input, &-select {
        // display button as input field
        padding: $input-padding-y $input-padding-x !important;
        -moz-padding-start: subtract($input-padding-x, 3px);
        font-family: $input-font-family;
        font-size: $input-font-size !important;
        font-weight: $input-font-weight !important;
        line-height: $input-line-height;
        color: $input-color;
        background-color: $input-bg;
        border: $input-border-width solid $input-border-color;
        box-shadow: none;
        width: 100%;
        text-align: start;

        &:hover {
            border-color: $input-border-color !important;
        }

        &:focus {
            color: $input-focus-color;
            background-color: $input-focus-bg;
            border-color: $input-focus-border-color;
            outline: 0;
        }
    }
    &-select {
        position: relative;
        &:after {
            position: absolute;
            font-family: "Font Awesome 6 Pro";
            font-weight: 400;
            content: "\f107"; // times
            right: 10px;
        }
    }

    // responsive block buttons
    @each $breakpoint in map-keys($grid-breakpoints) {
        &-#{$breakpoint}-block {
            @media(max-width: map-get($grid-breakpoints, $breakpoint)) {
                width: 100% !important;
            }
        }
    }
}

.btn-rounded {
    border-radius: 2em;
}

//
// light button
// 
.btn-light,
.btn-white {
    color: $gray-900 !important;
}

.btn-white {
    border-color: $gray-300;

    &:hover,
    &:focus {
        background-color: $gray-100;
        border-color: $gray-100;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($gray-300, .3);
    }
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    background-color: transparent;
    &:hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
        background-color: transparent;
        border-color: transparent;
    }
    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        border-color: transparent;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    } // No need for an active state here
} 
//
// Alternate buttons
//
@each $color,
$value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}

// 
// Button labels
//

.btn-label {
    margin: -.55rem .9rem -.55rem -.9rem;
    padding: .6rem .9rem;
    background-color: rgba($gray-900,0.1);
}

.btn-label-right {
    margin: (-$btn-padding-y) (-$btn-padding-x) (-$btn-padding-y) ($btn-padding-x);
    padding: ($btn-padding-y) ($btn-padding-x);
    background-color: rgba($gray-900,0.1);
    display: inline-block;
}

// 
// Button Extra Small Size
// 

.btn-xs {
    padding: .2rem .6rem;
    font-size: .75rem;
    border-radius: .15rem;
}

// Button as checkbox
.btn-check {
    &:checked + label {
        @each $color, $value in $theme-colors {
            &.btn-outline-#{$color} {
                box-shadow: 0 0 0 0.23rem rgba($value, .5);
            }
        }
    }
    &:checked + label :is(img, .card, [class^="avatar-"]) {
        border: 2px solid rgba($primary, .5);
    }
    &:not(:checked):not([disabled]) + label :is(img, .card, [class^="avatar-"]) {
        &:hover {
            border: 1px solid $gray-400;
            cursor: pointer;
        }
    }
    &:not(:checked):focus + label {
        background: inherit;
        color: inherit;
    }
}

//
// Soft Buttons
// 

@mixin button-variant-soft($bg) {
    color: $bg;
    background-color: rgba($bg, 0.18);
    border-color: rgba($bg, 0.12);

    &:hover {
        color: $white;
        background-color: $bg;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($bg, .3);
    }
}

@each $color, $value in $theme-colors {
    .btn-soft-#{$color} {
        @include button-variant-soft($value);
    }
  }

button.close {
    appearance: none;
    border: 0;
    background: inherit;
    font-size: 1.4em;
    font-weight: 700;
    color: $gray-700;
}