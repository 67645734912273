:root {
  --collapsible-show-lines: 2;
  --collapsible-line-height: 1.7em;
  --collapsible-overlay-height: 100%;
  --collapsible-less: 'Close';
  --collapsible-more: 'Read more';
  --collapsible-overlay-color-start: rgba(255, 255, 255, 0);
  --collapsible-overlay-color-end: rgba(255, 255, 255, 1);
  --divider-overlay-bg: white;
}
body {
  font-family: $font-family-secondary;
  font-weight: 400;
  background-color: $white;
}

#posting_page {
  // user/editor generated content
  h2,h3,h4,h5,h6 {
      font-size: 20px;
  }
  .page-body {
    img { width: 100%; }
  }
}

.subheader {
  color: $gray-700;
  @include content-styling;
  @media (min-width: $desktop-start) {
    font-size: 24px;
  }
  @media (max-width: $mobile-end) {
    font-size: 18px;
  }
  &-sm {
    @include content-styling;
    color: $gray-700;
    font-size: 16px;
  }
  &-xs {
    @include content-styling;
    color: $gray-700;
    font-size: 12px;
  }
}

.bg {
  &-red { background: $red; }
}

.btn {
  font-weight: 700;
  padding: 0.8rem 1.9rem;
  word-wrap: normal;
  &-md { padding: 0.45rem 0.9rem }
  &-sm { padding: 0.28rem 0.8rem }
  &-tiny { font-size: 10px; padding: 4px 8px;}
  &-block { width: 100%; /* removed in Bootstrap 5 */ }
  &-hover-cursor {
    position: relative;
    &:hover {
      &:before {
        position: absolute;
        content: '';
        right: 10px;
        top: 10px;
        @include branding-cursor(7px,28px,$white);
      }
    }
  }

  &:hover {
    border-color: transparent;
  }

  &-default-padding {
    padding: 0.45rem 0.9rem;
  }

  &-primary {
    color: $black;
    &:hover {
      background: $yellowdark;
      color: $black;
    }
  }

  @each $brand, $color in $social-colors {
    &-#{$brand} {
      background: $color;
      color: white;
      &:hover {
        color: white;
        background: darken($color, 5%);
      }
    }
  }
}

.primary-links {
  a { @include branded-link-styling(1px); }
}

.review-card {
  @include review-card;
}

.comparison-card {
  @include comparison-card;
}

.product-card {
  @include product-card;
}

.user-card {
  @include user-card;
}

.question-card {
  @include question-card;
}

.comment-form-card {
  @include comment-form;
}

.comment-card {
  @include comment-card;
}

.report-download {
  @include report-card;
}

.lead-gen {
  @media(max-width: map-get($grid-breakpoints, "sm")) {
    @include lead-gen;
  }
}

.list-links {
  @include list-links;
}

div:has(> .posting-card) {
  container: posting / inline-size;
}
.posting-card {
  @include block-link(1);
  @include hide-empty-nodes;
  .date-lg { display: none; }
}

//map-get not recognized within the container query
@container posting (min-width: 1000px) {
  .posting-card {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: auto;
    .date-lg { display: flex; }
    & > .row {
      &:first-child {
        // This assumes the user is within the first element
        grid-column: 1;
        grid-row: span 3;
        align-self: start;
        > * { width: 90%; }
        .user-card {
          @include user-card-vertical;
        }
        // hide everything that is not the user element
        > :not(:has(.user-card)) {
          display: none;
        }
      }
      &:not(:first-child) {
        grid-column: 2;
      }
    }
  }
}

.branded-top-bar {
  @include branded-top-bar(1px);
}

.report_image {
  @include report-image;
}

.block-link-element {
  @include block-link(1);
}

.badge {
  margin: 0 0.5em 0.5em 0;
  text-transform: uppercase;
  i { margin-right: 0.375rem; }
  &-real-user, &-user, &-msp, &-reseller {
    background: lighten($success, 30%);
    color: darken($success, 5%);
  }
  &-community-manager {
    background: lighten($warning, 30%);
    color: darken($warning, 5%);
  }
  &-vendor {
    background: lighten($info, 30%);
    color: darken($info, 5%);
  }
  &-outlined {
    background: transparent;
    @each $color, $value in $theme-colors {
      &.badge-#{$color} {
        border: 1px solid $value;
        color: $value;
      }
    }
  }
}

.css-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--avatar_size);
  height: var(--avatar_size);
  margin-right: 1rem;
  font-size: 15px;
  border-radius: 50%;
  background-color: $gray-400;
  color: $dark;
}

.avatar-palette {
  $palette-count: length($avatar-palette);
  @each $foreground, $background in $avatar-palette {
    $index: index($avatar-palette, ($foreground, $background));
    &:nth-child(#{$palette-count}n+#{$index} of .avatar-palette) {
      .css-avatar {
        color: $foreground;
        background-color: $background;
        border: 1px solid $gray-400;
      }
    }
  }
}

.avatar-gallery {
  display: flex;
  align-items: center;
  .css-avatar, img {
    margin: 0 0 0 -10px;
    &:first-child {
      margin-left: 0;
    }
  }
  img {
    box-shadow: -1px 1px 0px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid white;
  }
}


.redactor-box {
  width: 100%;
  flex-grow: 1;
}

.remove-item {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  &-input {
    right: 25px;
    top: 22%;
  }
  &-video {
    color: white;
    top: 10px;
    right: 25px;
  }
  &:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 400;
    content: "\f00d"; // times
  }
}

// redactor text cleanup
p > br { display: none; }
.content-cleanup {
  :is(h1, h2, h3, h4, h5, h6) {
    font-size: 16px;
    font-family: $font-family-secondary;
    font-weight: 700;
  }
}

.search-form {
  padding: 10px 13px 10px 20px;
  border: 1px solid $gray-400;
  border-radius: 2px;
  position: relative;
  .system-search-form {
    @include search-form;
    .search-close { display: none; }
    .autocomplete-suggestions { margin-top: 1px; }
  }
}

.search {
  @include autocomplete;
}

.scroll-nav {
  @media(max-width: map-get($grid-breakpoints, "md")) {
    &:before {
      content: "\f054";
      font-family: "Font Awesome 6 Pro";
      font-size: 15px;
      font-weight: 300;
      color: $secondary;
      position: absolute;
      right: 0;
      width: 20px;
      height: 20px;
      margin-top: -33px;
      cursor: pointer;
    }

    &-left {
      &:before {
        content: "\f053";
        left: 0;
        right: unset;
        margin-top: 8px;
        padding-left: 10px;
      }
    }
  }
}

.after {
  &-topbar { top: -120px; }
  &-stickybar { top: -47px; }
}

/** Usage:
   * Add .sticky to the block that should stick to the top
   * Set data-stick-after to determine when the element should stick
 */
.sticky {
  display: none;
  &.stick {
    display: block;
    position: fixed;
    top: 0;
    z-index: 1080;
  }
  // never show sticky items on smaller devices
  @media(min-width: map-get($grid-breakpoints, "md")) {
    &-topbartabs { top: 145px !important; }
    &-topbar { top: 85px !important; }
    &-stickybar { top: 50px !important; }
  }
  @media(max-width: map-get($grid-breakpoints, "md")) {
    &-topbar, &-stickybar { top: 50px !important; }
  }
}

/** Usage:
   * Add .collapsible to the block
   * Customizable variables (defaults set above in :root):
   *    --collapsible-more
   *    --collapsible-less
   *    --collapsible-show-lines
   *    --collapsible-line-height
   *    --collapsible-overlay-height
   *    --collapsible-overlay-color-start
   *    --collapsible-overlay-color-end
   * Add .collapsible-by-height to avoid using line-clamp (for long content, with images, videos, etc..) using --collapsible-overlay-height
   * Add .collapsible-end to position the collapsible toggle on the right side with the overlay at the end
   * Add .collapsible-center to position the collapsible toggle in the center of the overlay
   * Add .collapsible-toggle-button to display a button instead of a link
   * Add .collapsible-hide-toggle to hide the "after" (Read more) link. Used for when the toggle is controlled by the html, not css
   * Accepts data-scroll-to-on-close` on the collapsible element
 */
div:has(> .collapsible) {
  cursor: text;
}
.collapsible {
  position: relative;
  transition: all 300ms ease-out;
  --zero: 0px; // Webpack removing 'px', force '0px' value here
  pointer-events: none;
  a {
    pointer-events: auto !important;
    position: unset !important; // override block styling
  }
  &:not(.open) {
    overflow: hidden;
    &:not(.collapsible-by-height) {
      line-clamp: var(--collapsible-show-lines);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: var(--collapsible-show-lines);
      :is(p, ul, ol, li, ul br) { display: inline; /* fix for safari and FF */ }
    }
    &:before {
      position: absolute;
      content: '';
      //  Calculate the positioning to make sure the collapsible is not applied where not needed
      //  Ensure a large negative or positive number, ensure that 100% and the calculated height don't match
      bottom: min(var(--zero), calc((calc(100% + 5px) - calc(var(--collapsible-show-lines) * var(--collapsible-line-height))) * 999));
      height: var(--collapsible-overlay-height);
      width: 100%;
      background: linear-gradient(to bottom, var(--collapsible-overlay-color-start), var(--collapsible-overlay-color-end) 95%);
    }
    &:not(.collapsible-hide-toggle) {
      &:after {
        content: var(--collapsible-more);
        bottom: min(var(--zero), calc((calc(100% + 5px) - calc(var(--collapsible-show-lines) * var(--collapsible-line-height))) * 999));
        left: 0;
      }
    }
    &.collapsible-by-height {
      max-height: calc(var(--collapsible-show-lines) * var(--collapsible-line-height));
    }
  }
  &.open {
    // Formula is correct but adding px to 100% height doesn't work
    //height: min(calc(100% + 35px), max(100%, calc((calc(100% + 1px) - calc(var(--collapsible-show-lines) * var(--collapsible-line-height))) * 999)));
    height: auto;
    padding-bottom: 35px; // spacing for close link, hard to remove when not needed since padding is relative to width
    &.collapsible-toggle-button {
      padding-bottom: 65px;
    }
    &:not(.collapsible-hide-toggle) {
      &:after {
        content: var(--collapsible-less);
        bottom: min(var(--zero), calc((calc(100% + 5px) - calc(var(--collapsible-show-lines) * var(--collapsible-line-height))) * 999));
        left: 0;
      }
    }
  }
  &.collapsible-end {
    &:before {
      right: 0;
      width: 60% !important;
      height: var(--collapsible-line-height) !important;
      background: linear-gradient(to right, var(--collapsible-overlay-color-start), var(--collapsible-overlay-color-end) 70%) !important;
    }
    &:not(.collapsible-hide-toggle) {
      &:after {
        right: 0;
        left: unset;
      }
    }
  }
  &.collapsible-center {
    &:after {
      right: 0;
      left: 0;
      text-align: center;
      margin: 0 auto;
    }
  }
  &:not(.collapsible-hide-toggle) {
    &:after {
      position: absolute;
      z-index: 1070;
      pointer-events: all;
      width: auto;
      font-size: 16px;
      font-weight: bold;
      font-family: $font-family-secondary;
      @include branded-link-styling(1px);
    }

    &:hover:after {
      background: $primary;
      text-decoration: none;
      cursor: pointer !important;
    }

    // reset default styling on "close" link, if an internal link is hovered
    &:has(a:hover):after {
      background: none !important; /* Remove hover styles if links are hovered over */
      text-decoration: underline;
    }

  }
  &.collapsible-toggle-button {
    &:after {
      width: min(250px, 100vw) !important;
      text-decoration: none;
      background: $white;
      border: 1px solid $secondary;
      border-radius: 3px;
      padding: 10px 0;
    }
    &:hover:after {
      background: lighten($primary, 25%);
    }
  }
}

.rating {
  i {
    margin-right: clamp(0.1rem, 0.2vw, 0.3rem);
    font-size: clamp(0.85rem, 2vw, 1rem);
  }
}

// intl-tel-input phone input
.iti {
  display: block !important;
  .iti__search-input {
    padding: 0.5em;
    &:focus-visible { outline: none; }
  }
}
.intl-tel-input {
  .arrow { pointer-events: none; }
}

.list-with-checks, .list-with-primary-checks {
  display: flex;
  flex-flow: column wrap;
  text-align: left;
  row-gap: 10px;
  div {
    font-size: 16px;
    color: $secondary;
    &:before {
      color: $green;
      font-size: 12px;
      font-family: "Font Awesome 6 Pro";
      font-weight: 400;
      content: '\f00c';
      padding-right: 25px;
    }
  }
}

.list-with-primary-checks {
  div {
    display: flex;
    align-items: baseline;
    &:before {
      display: inline-block;
      background: center url('../../images/v.png') no-repeat;
      height: 13px;
      width: 40px;
      font-family: unset;
      content: '';
      padding: 0;
      margin-right: 10px;
    }
  }
}

.remove-links {
  a {
    color: currentColor !important;
    cursor: not-allowed;
    text-decoration: none !important;
  }
}

.modal-lead-sizing {
  height: clamp(570px, 62vh, 100%);
}

.break {
  flex-basis: 100%;
  height: 0;
}

.product-img-fluid {
  width: clamp(40px, 15vw, 100px);
  height: clamp(40px, 15vw, 100px);
}

/**
  Filters
*/
.dropdown-filter {
  font-family: $font-family-secondary;

  .dropdown-toggle {
    display: flex;
    padding: 12px 0;
    position: relative;
    transition: all .4s;
    font-size: 1rem;
    font-weight: 700;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    color: $gray-900;
    &:hover {
      color: darken($gray-900, 10%);
    }
  }

  &.show {
    z-index: 3; /* to prevent collapsed left menu on hover from going behind the PeerQuotes filters */
  }

  .filters-dropdown-menu {
    position: relative;
    width: 100%;
    box-shadow: none;
    border: none;
    padding: 0px;
    font-size: 0.80rem;
    overflow: hidden;
    overflow-y: auto;

    >ul {
      margin-bottom: 0px;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: #6c757d50;
      }
    }

    .dropdown-item {
      padding: 5px 0;
      display: block;
      position: relative;
      transition: all .4s;
      cursor: pointer;
      color: $secondary;

      &:hover {
        color: $gray-900;
        background-color: initial;
      }
    }
  }
}

a#to-top {
  width: 40px;
  height: 40px;
  display: flex;
  background-color: rgba(232, 233, 234, 0.76);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  bottom: 40px;
  right: 8%;
  transition: all 250ms linear;
  &:hover {
    background-color: rgba(232, 233, 234, 0.96);
  }
  &:after {
    content: "";
    border: 2.5px solid #fff;
    border-bottom: 0;
    border-right: 0;
    border-radius: 2px;
    margin-top: 5px;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
  }
}

.spyscroll-wrapper {
  position: relative;
  height: 500px;
  overflow: auto;
  padding: 0 10px;
}

.content-styling {
  @include content-styling;
}

.gitb-revealable {
  display: none;
}

.row:has(.hero-thumbnail) {
  @media(min-width: map-get($grid-breakpoints, "md")) {
    position: relative;
    min-height: 320px;
  }
}
.hero-thumbnail {
  @media(min-width: map-get($grid-breakpoints, "md")) {
    position: absolute;
    right: 20px;
    bottom: -40px;
  }
  @media(max-width: map-get($grid-breakpoints, "md")) {
    display: none;
    //img {
    //  max-width: 100%;
    //  height: auto;
    //}
    //text-align: center;
  }
}

.gallery {
  @media(max-width: map-get($grid-breakpoints, "md")) {
    width: 100vw;
  }
  .gallery-items {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    > * > * { height: 100%; }
    @media(max-width: map-get($grid-breakpoints, "md")) {
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      scroll-padding-left: 24px;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      > * {
        scroll-snap-align: start;
        display: inline-block;
        &:first-child {
          box-sizing: content-box;
          > * {
            margin-left: 24px;
          }
        }
      }
    }
  }
  .gallery-nav {
    @media(min-width: map-get($grid-breakpoints, "md")) {
      display: none;
    }
    @media(max-width: map-get($grid-breakpoints, "md")) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      gap: 20px;
      margin-top: 30px;
      > * {
        appearance: none;
        border: 0;
        background: none;

        &:before {
          font-family: 'Font Awesome 6 Pro';
          font-size: 15px;
          font-weight: 900;
          color: $gray-500;
          content: '\f111';
          cursor: pointer;
        }

        &.active {
          &:before {
            color: $dark;
          }
        }
      }
    }
  }
}

.branded-flag {
  position: relative;
  &-end {
    &:after {
      @include branding-flag-bottom-end(var(--flag-size), var(--flag-color));
    }
  }
  &:not(-end) {
    &:after {
      @include branding-flag-bottom(var(--flag-size), var(--flag-color));
    }
    &.flag-quote {
      &:after {
        bottom: calc(var(--flag-size) * -1);
        left: var(--flag-size);
      }
    }
  }
}
.hover-play-icon {
  --play-icon-color: white;
  --play-icon-size: 60px;
  position: relative;
  z-index: 1;
  &:before {
    z-index: 10;
    content: '';
    font-family: "Font Awesome 6 Pro";
    font-size: var(--play-icon-size);
    font-weight: 900;
    color: var(--play-icon-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover:before {
    content: "\f144";
    cursor: pointer;
  }
}

.gitb-dynamic-video {
  cursor: pointer;
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
  &:hover {
    opacity: 0.8;
    .gitb-yt-play-button {
      &:before {
        color: lighten($youtube-color, 15%);
      }
    }
  }
}
.gitb-yt-play-button {
  &:before {
    z-index: 10;
    font-family: "Font Awesome 6 Brands";
    content: "\f167";
    font-size: 60px;
    font-weight: 900;
    color: $youtube-color;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:after {
    z-index: 9;
    content: '';
    width: 20px;
    height: 20px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.scroll-sync {
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  transition: height 0.5s ease;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  > * {
    scroll-snap-align: start;
  }
}

.divider {
  &-horizontal {
    width: 100%;
    height: 1px;
    background: $gray-400;

    &.with-overlay {
      position: relative;
      &:before {
        position: absolute;
        content: var(--divider-overlay-txt);
        background-color: var(--divider-overlay-bg);
        color: $gray-600;
        font-size: 14px;
        padding: 0 0.75em;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
}

.legend-marker {
  height: 5px;
  width: 20px;
  display: inline-block;
  border-radius: $progress-border-radius;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.sortable {
  th {
    &:not([data-sortable="false"]) {
      cursor: pointer;
    }
    &:not([data-sortable="false"]):after {
      font-family: "Font Awesome 6 Pro";
      font-weight: 300;
      content: '\f07d';
      color: $dark;
      margin-left: 10px;
      font-size: 13px;
    }
  }
}

audio {
  max-width: 100%;
  &.primary {
    &::-webkit-media-controls-panel {
      background: $primary;
    }
  }
  &.white {
    &::-webkit-media-controls-panel {
      background: $white;
      padding: auto 0;
    }
  }
  &.light {
    &::-webkit-media-controls-panel {
      background: $gray-100;
      padding: auto 0;
    }
  }
  &.transparent-dark {
    filter: invert(1) hue-rotate(180deg) brightness(0.85) contrast(1.2); /* Adjust controls to white */
    background-color: transparent !important;
    color: white;
    outline: none!important;
    border: none!important;
    border-radius: 0!important;
    -webkit-appearance: none;
    appearance: none; 
    padding: 0; 
    margin: 0;

    /* Remove border-radius from WebKit media controls */
    &::-webkit-media-controls-panel {
      outline: none !important;
      border: none !important;
      border-radius: 0 !important;
      background-color: transparent !important;
      color: white;
      padding: 0!important;
      margin: 0!important;
    }

    &::-webkit-media-controls, &::-webkit-media-controls-enclosure {
      border-radius: 0 !important;
      color: white;
      background-color: transparent !important;
    }

    &::-webkit-media-controls-timeline,
    &::-webkit-media-controls-play-button,
    &::-webkit-media-controls-volume-slider-container {
      border-radius: 0 !important;
      color: white;
    }
  }
  &.dark {
    &::-webkit-media-controls-panel {
      background: $dark;
      color: white;
      padding: auto 0;
    }
    &::-webkit-media-controls-play-button,
    &::-webkit-media-controls-volume-slider,
    &::-webkit-media-controls-timeline,
    &::-webkit-media-controls-current-time-display,
    &::-webkit-media-controls-time-remaining-display {
        color: white;
    }
  }
}

.filler-dots {
  flex-grow: 1;
  overflow: hidden;
  &::after {
    content: '........................................................................................';
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.branding-p {
  // will have to eventually set classes for -xl,-lg,-md etc.. passing in data attributes does not work
  z-index: 1;
  &.branding-p-xl {
    @media (min-width: $desktop-start) {
      @include branding-p(180px,180px,10px,white);
      i { font-size: 50px; }
    }
    @media (max-width: $mobile-end) {
      @include branding-p(72px,72px,6px,white);
      i { font-size: 30px; }
    }
  }

  &.branding-p-sm {
    @include branding-p(17px,17px,1px,white);
    i { font-size: 12px; }
  }

  // bootstrap 5: replace positioning attributes with classes
  top: 0;
}