// 
// custom-radio.scss
//

.radio {
    label {
        display: inline-block;
        padding-left: 8px;
        position: relative;
        font-weight: normal;
        margin-bottom: 0;
        &::before {
            background-color: transparent;
            border-radius: 50%;
            border: 2px solid $text-muted;
            content: "";
            display: inline-block;
            height: 18px;
            left: 0;
            margin-left: -18px;
            position: absolute;
            transition: border 0.5s ease-in-out;
            width: 18px;
            outline: none !important;
        }
        &::after {
            background-color: $gray-700;
            border-radius: 50%;
            content: " ";
            display: inline-block;
            height: 10px;
            left: 6px;
            margin-left: -20px;
            position: absolute;
            top: 4px;
            transform: scale(0, 0);
            transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            width: 10px;
        }
    }
    input[type="radio"] {
        cursor: pointer;
        opacity: 0;
        z-index: 1;
        outline: none !important;
        &:disabled+label {
            opacity: 0.65;
        }
    }
    input[type="radio"]:focus+label {
        &::before {
            outline-offset: -2px;
            outline: 5px auto -webkit-focus-ring-color;
            outline: thin dotted;
        }
    }
    input[type="radio"]:checked+label {
        &::after {
            transform: scale(1, 1);
        }
    }
    input[type="radio"]:disabled+label {
        &::before {
            cursor: not-allowed;
        }
    }

    &.radio-inline {
        margin-top: 0;
    }
    
    &.radio-single {
        label {
            height: 17px;
        }
    }
}

@each $color,
$value in $theme-colors {
    .radio-#{$color} {
        input[type="radio"]+label {
            &::after {
                background-color: $value;
            }
        }
        input[type="radio"]:checked+label {
            &::before {
                border-color: $value;
            }
            &::after {
                background-color: $value;
            }
        }
    }
}