// 
// card.scss
//
@use "sass:math";
.card {
    background: $white;
    box-sizing: border-box;
    border: 1px solid $gray-400;
    border-radius: 5px;
    padding: 2em;
    margin-bottom: 2em;
    &:hover, &:focus-within {
        box-shadow: 0px 22px 55px 10px rgba(0, 0, 0, 0.09), 0px 7px 15px -5px rgba(0, 0, 0, 0.09), 0px 2px 10px rgba(0, 0, 0, 0.09);
    }
    &:active {
        //background: $gray-200;
    }
    @media(max-width: map-get($grid-breakpoints, "sm")) {
        padding: 1em;
    }
    &.card-pane {
        &:hover, &:focus-within {
            box-shadow: none;
        }
        .card-title {
            color: $secondary;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.01em;
        }
        @media(min-width: map-get($grid-breakpoints, "md")) {
            border-radius: 0;
            padding: 20px;
            border-top: 7px solid $gray-400;
            margin-top: 1.5rem;
        }
        @media(max-width: map-get($grid-breakpoints, "md")) {
            border: 1px solid $gray-400;
            border-radius: 5px;
            padding: 1em;
            margin-bottom: 2em;
            margin-top: 0;
            .card-title {
                font-size: 18px;
                font-weight: bolder;
                text-transform: none;
                margin-top: 40px;
            }
            .list-group-item {
                padding-left: 0;
            }
        }
    }
}

.card-drop {
    font-size: 20px;
    line-height: 0;
    color: inherit;
}

.card-widgets {
    float: right;
    height: 16px;
    > a {
        color: inherit;
        font-size: 18px;
        display: inline-block;
        line-height: 1;

        &.collapsed {
            i {
                &:before {
                    content: "\F0415";
                }
            }
        }
    }
}


// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
}


//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: $card-overlay-bg;
    cursor: progress;

    .card-portlets-loader {
        background-color: $card-overlay-color;
        animation: rotatebox 1.2s infinite ease-in-out;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 3px;
        margin-left: - math.div($grid-gutter-width, 2);
        margin-top: - math.div($grid-gutter-width, 2);
    }
}

@keyframes rotatebox {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

// Custom card box
.card-box {
    border: 1px solid $card-border-color;
    background-color: $card-bg;
    padding: $card-spacer-x;
    box-shadow: $shadow-sm;
    margin-bottom: $grid-gutter-width;
    @include border-radius($card-border-radius);
}

.header-title {
    font-size: 1rem;
    margin: 0 0 7px 0;
}

.sub-header {
    font-size: 0.875rem;
    margin-bottom: $grid-gutter-width;
    color: $text-muted;
}