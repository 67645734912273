// 
// popover.scss
//


.popover-header {
    margin-top: 0;
}

.popover-body {
    font-family: $font-family-secondary;
    a { color: $blue-link; }
}