#top-bar {
  @media(min-width: $desktop-start) {
    background: $white;
    //box-shadow: 0px 1px 0px $gray-200;
    border-bottom: 1px solid $gray-400;
    .container-fluid + div {
      margin: 0 1.5rem !important;
    }
    .home-logo {
      width: 152px;
      img { width: 152px; }
    }
    .mobile-nav,.mobile-nav-close { display: none; }
    z-index: 2000; // needed for sticky behavior
    nav {
      position: relative; /* need this only for desktop */
      &:has(.dropdown:hover) {
        position: static;
      }
    }
    .nav {
      a.nav-link {
        border-bottom: 2px solid $white;
        &.dropdown-toggle { pointer-events: none; }
        &:hover, &.nav-active {
          border-bottom: 2px solid $primary;
        }
        font-weight: normal;
        padding: 13px 20px 11px;
      }
      .dropdown:hover {
        > .nav-link {
          border-bottom: 2px solid $primary;
        }
        > .dropdown-menu {
          display: block;
          margin-top: 1px; // to see the top bar's bottom border
          > .dropdown-item {
            padding: 0;
            &:hover {
              background: $primary;
              .dropdown-toggle + .dropdown-menu, .dropdown-menu:hover {
                display: block;
                max-height: calc(100vh - 120px);
                padding-bottom: 15px;
                overflow-y: auto;
                box-shadow: 10px 3px 20px rgba(0, 0, 0, 0.1), -1px 0 0 rgba(0, 0, 0, 0);
                border-left: 1px solid $gray-400;
                background: $light;
              }
            }
            .nav-link {
              border: none;
              padding: 6px 15px;
              &:first-child {
                padding-top: 8px!important;
              }
            }
            // for submenu
            .dropdown-menu {
              inset: 0 auto auto 0;
              left: 100%;
              padding: 0;
              overflow-x: hidden;
              width: max-content;
              .dropdown-item {
                white-space: normal;
                word-wrap: normal;
                line-height: 1.2;
              }
              /* the max width is set dynamically in general_scripts.js */
              .dropdown-item, .dropdown-item a {
                margin: 0;
              }
            }
          }
        }
      }
    }
    .search-trigger {
      color: $gray-700;
      font-size: 20px;
      cursor: pointer;
      margin-right: 1em;
      i { display: block; }
    }
  }
  @media(max-width: 940px) and (min-width: $mobile-end) {
    #home-main-menu-item { display: none; }
  }
  #me-menu {
    cursor: pointer;
    @media(min-width: map-get($grid-breakpoints, "md")) {
      .dropdown-toggle {
        width: 50px;
        height: 50px;
        padding-top: 5px;
      }
    }
    @media(max-width: map-get($grid-breakpoints, "md")) {
      .dropdown-toggle {
        width: 40px;
        height: 40px;
      }
      .dropdown-menu {
        margin-top: 5px !important;
        top: 0 !important;
      }
    }
    .message-count {
      position: absolute;
      top: 5px;
      left: 75%;
    }
  }
  nav {
    .system-search-form {
      position: absolute;
      padding: 14px 17px;
      display: none;
      background: #fff;
      box-sizing: border-box;
      border: 1px solid $gray-400;
      width: 100%;
      z-index: 5000;

      @media(min-width: $desktop-start) {
        height: 40px;
        top: 4px;
        border-radius: 25px;
      }

      @media(max-width: $mobile-end) {
        left: 0;
        top: 50px;
        .submit-form {
          color: $gray-900;
        }
      }
      @include search-form;
      input::placeholder {
        color: $gray-400;
        font-weight: 300;
      }
    }
  }

  @media(max-width: $mobile-end) {
    z-index: 2000; // needed for sticky behavior
    &:not(.sticky-top) {
      position: relative;
    }
    background: $secondary;
    color: $white;
    .home-logo {
      width: 110px;
      img { width: 110px; }
    }
    #me-menu {
      .dropdown-menu {
        right: -1.75rem !important;
      }
      .btn {
        padding: 0.4rem 0.9rem;
      }
    }
    .search-trigger {
      margin-right: 1em;
    }
    nav {
      margin-left: auto;
      margin-right: 15px;
      display: flex;
      flex-display: column;
      > .nav {
        flex: 1 1 auto;
        display: none;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 100vh;
        background: $white;
        padding-top: 30px;
        z-index: 1;
        overflow: hidden;
        //i { display: none; }
        .dropdown {
          position: static !important;
        }
        a.nav-link:not(:has(img, span), .btn) {
          padding: 1rem 1.7rem;
          border-bottom: 1px solid $gray-400;
          position: relative;
          font-weight:  normal;
          &.dropdown-toggle {
            background: $white;
            &:after {
              position: absolute;
              right: 20px;
              font-family: 'Font Awesome 6 Pro';
              font-size: 15px;
              font-weight: 300;
              color: $secondary;
              content: '\f105';
            }
          }
        }
        .mobile-nav-close {
          position: absolute;
          right: 10px;
          top: 5px;
          i {
            display: block;
            margin: 10px;
            font-size: 15px;
          }
          color: $gray-700;
          padding: 0;
        }
        .dropdown-menu {
          @include slide-out;
          height: 100vh;
          padding-top: 0;
          > div:not(.dropdown-item) { height: 90%; padding-bottom: 20px; }
          a { font-size: 17px !important; }
          .nav {
            // multi-level menu
            flex-direction: column;
            margin-bottom: 0;
            .nav-link {
              background-color: white;
              border-bottom: 0 !important;
              font-size: 17px !important;
            }
          }
          .tab-content, div:has(> .tab-content) {
            background-color: white !important; // resets gray background on mobile.
          }
          .tab-pane {
            @include slide-out;
            height: 100vh;
            &:not(.show) {
              transform: translateX(100%); // right: -100% from the mixin above is buggy here
            }
            &.show {
              background-color: white;
              z-index: 999999;
            }
            a { font-size: 17px !important; }
          }
        }
      }
    }
  }
  a.nav-link {
    box-sizing: border-box;
    color: $black;
    font-size: 16px;
    font-weight: 300;
  }
}

.announcement-strip {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  font-weight: 500;
  @media(max-width: $mobile-end) {
    font-size: 14px;
    background-color: $primary;
    color: $secondary;
    a {
      color: $secondary;
      font-weight: 600;
      // Include if we ever want to put the link on the next line
      // display: block;
    }
  }
  @media (max-width: 372px) {
    font-size: 3.8vw;
  }
  @media(min-width: $desktop-start) {
    font-size: 18px;
    background-color: $secondary;
    color: white;
    a { color: $primary; }
  }
  i { padding-right: 8px; }
  a { text-decoration: underline; }
}
body:has(> .modal.show), body.modal-open {
  .announcement-strip { display: none; }
}
#footer {
  background: $secondary;
  .footer-logo {
    width: 40px;
    img { width: 40px; }
  }
  .footer-hdr {
    color: $gray-700;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 900;
    margin-bottom: 20px;
    @media(max-width: map-get($grid-breakpoints, "sm"))  {
      margin-top: 25px;
    }
  }
  @media(max-width: map-get($grid-breakpoints, "lg")) and (min-width: map-get($grid-breakpoints, "sm")) {
    .footer-links { justify-content: space-between; text-align: center; }
  }
  @media(max-width: map-get($grid-breakpoints, "sm"))  {
    text-align: center;
  }
  a {
    display: block;
    color: $white;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 16px;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
  .social {
    border-top: 1px solid #FFFFFF70;
    .copy {
      color: $brown;
      font-size: 16px;
      font-family: $font-family;
    }
    @media(max-width: map-get($grid-breakpoints, "lg")) {
      text-align: center;
      .social-links { order: 1; margin-bottom: 15px; }
      .copy { order: 2; }
    }
    @media(min-width: map-get($grid-breakpoints, "lg")) {
      .copy { order: 1 }
      .social-links { order: 2; text-align: right; }
    }
    a {
      display: inline-block;
      color: $brown;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.21em;
      margin: 0;
      @media(min-width: map-get($grid-breakpoints, "xl")) {
        margin-left: 65px;
      }
      @media(max-width: map-get($grid-breakpoints, "xl")) and (min-width: map-get($grid-breakpoints, "md")) {
        font-size: 12px;
        margin-left: 45px;
      }
      @media(max-width: map-get($grid-breakpoints, "md"))  {
        span { display: none; }
        &:not(:first-child) { margin-left: 20px; }
      }
      @media(min-width: map-get($grid-breakpoints, "md"))  {
        i { margin-right: 8px; }
      }
      // loops through social links to generate class specific hover colors
      @each $brand, $color in $social-colors {
        &.#{$brand}:hover {
          color: $color;
        }
      }
    }
  }
}
