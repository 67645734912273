// 
// type.scss
//

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 10px 0;
    font-family: $font-family;
    font-weight: 900;
    line-height: 1.2;
    color: $black;
}

h1,.h1 {
    @media (min-width: $desktop-start) {
        font-size: 32px;
    }
    @media (max-width: $mobile-end) {
        font-size: 24px;
    }
}

h2,.h2 {
    @media (min-width: $desktop-start) {
        font-size: 24px;
    }
    @media (max-width: $mobile-end) {
        font-size: 18px;
    }
}

h3,.h3 {
    @media (min-width: $desktop-start) {
        font-size: 22px;
    }
    @media (max-width: $mobile-end) {
        font-size: 15px;
    }
}

h4,.h4 {
    @media (min-width: $desktop-start) {
        font-size: 20px;
    }
    @media (max-width: $mobile-end) {
        font-size: 15px;
    }
}

// Font sizes (Custom)
@for $i from 9 through 28 {
    .font-#{$i} {
        font-size: #{$i}px !important;
    }
}


// Font Weight

.font-weight-medium {
    font-weight: $font-weight-medium;
}

.font-weight-semibold {
    font-weight: $font-weight-semibold;
}


.list-inline {
    margin-bottom: 0;
    .list-inline-item {
        &:not(:first-child) {
            &:before {
                content: '|';
                color: $gray-400;
                padding: 0 10px;
            }
        }
        &:not(:last-child) { margin-right: 0; }
    }
    &.list-justify-between {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .list-inline-item {
            flex-grow: 1;
            flex-basis: auto;
            text-align: center;
            padding: 0 0.7em;
            &:not(:first-child) {
                &:before {
                    content: '';
                    padding: 0;
                }
                border-left: 1px solid $gray-400;
            }
        }
    }
}