//
// slide-sidebar.scss
//

.slidebar-sm {
    width: 20%;
}

.slidebar-md {
    width: 40%;
}

.slidebar-lg {
    width: 60%;
}

.slidebar-xl {
    width: 75%;
}

.slidebar-right-sm {
    right: -20%;
}

.slidebar-right-md {
    right: -40%;
}

.slidebar-right-lg {
     right: -60%;
 }

.slidebar-right-xl {
    right: -75%;
}

.slidebar-left-sm {
    left: -20%;
}

.slidebar-left-md {
    left: -40%;
}

.slidebar-left-lg {
    left: -60%;
}

.slidebar-left-xl {
    left: -75%;
}

.slidebar {
    background-color: #ffffff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    display: block;
    position: fixed;
    transition: all 200ms ease-out;
    // width: $rightbar-width;
    z-index: 9999;
    float: right !important;
    // right: -($rightbar-width + 10px);
    top: 0;
    bottom: 0;

    .slidebar-toggle {
        &:hover {
            cursor: pointer;
        }
        &.admin-toggle {
            position: absolute;
            width: 40px;
            height: 40px;
            margin-top: 22vh;
            left: 0;
            margin-left: -40px;
            border-radius: 5px 0 0 5px;
            background: $secondary;
            color: white;
            &:before {
                font-family: "Font Awesome 6 Pro";
                font-weight: 700;
                content: "\f013"; // cog
                color: white;
                position: absolute;
                width: 100%;
                top: 11%;
                text-align: center;
                font-size: 1.3rem;
            }
        }
    }
    
    .slidebar-title {
        background-color: $gray-200;
        padding: 16px 25px;
        color: $secondary;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }

    .user-box {
        padding: 25px;
        text-align: center;

        .user-img {
            position: relative;
            height: 64px;
            width: 64px;
            margin: 0 auto 15px;

            .user-edit {
                position: absolute;
                right: -5px;
                bottom: 0px;
                height: 24px;
                width: 24px;
                background-color: $white;
                line-height: 24px;
                border-radius: 50%;
                box-shadow: $shadow-lg;
            }
        }

        h5 {
            margin-bottom: 2px;

            a {
                color: $dark;
            }
        }
    }

    .notification-item {
        .media {
            padding: 0.75rem 1rem;

            &:hover {
                background-color: $dropdown-link-hover-bg;
            }
        }

        .user-status {
            position: absolute;
            right: 0px;
            bottom: -4px;
            font-size: 10px;

            &.online {
                color: $success;
            }

            &.away {
                color: $warning;
            }

            &.busy {
                color: $danger;
            }
        }
    }
}

// Slidebar overlay
.slidebar-overlay {
    background-color: rgba($dark, 0.2);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 9998;
    transition: all .2s ease-out;
    &.show { display: block; }
}

[class^="slidebar-right-"] {
    right: 0;
}

.slidebar-enabled {

    &.slidebar-right-sm {
        right: 0;
    }
    &.slidebar-right-md {
        right: 0;
    }
    &.slidebar-right-lg {
        right: 0;
    }

    &.slidebar-right-xl {
        right: 0;
    }

    &.slidebar-left-sm {
        left: 0;
    }
    &.slidebar-left-md {
        left: 0;
    }
    &.slidebar-left-lg {
        left: 0;
    }
    &.slidebar-left-xl {
        left: 0;
    }
}

@media(max-width: map-get($grid-breakpoints, "md")) {
    .slidebar {
        overflow: auto;

        .slimscroll-menu {
            height: auto !important;
        }
    }
}

#body {
    display: flex;
    flex-direction: column;
}

.custom-scroller {
    position: relative;
    overflow-y: auto;
  
    /* width */
    &::-webkit-scrollbar {
      max-width: 5px;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background-color: #c5ced6;
      border-radius: 10%;
    }

    &.custom-scroller-transparent {
        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
    }

    &.custom-scroller-show-track {
        &::-webkit-scrollbar-track {
            background-color: #e6eaed;
        }
    }
  
    /* Handle on hover */
    // ::-webkit-scrollbar-thumb:hover {
    //     background-color: #555;
    // }
  
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: #6c757d50;
      }
    }
  }