// 
// dropdown.scss
//

:root {
    --product-logo-size: 60px;
}

.dropdown-menu {
    color: $gray-600 !important;
    font-size: 15px;
    padding: 20px 0;
    box-shadow: 0 3px 20px #00000029;
    animation-name: none;
    border-radius: 0;
    border: 0;
    margin: 0;
    position: absolute;
    z-index: 1090;
    transform: none !important;
    &:not(.dropdown-menu-up):not(.dropdown-menu-last-ellipsis).show {
        top: 100%!important;
        height: fit-content;
    }
    &.dropdown-menu-up.show {
        bottom: 100%!important;
        height: fit-content;
    }
    &.dropdown-menu-last-ellipsis.show {
        bottom: 100%!important;
        height: fit-content;
        transform: translate(-20px, 50%) !important;
    }

    i {
        display: inline-block;
    }

    .dropdown-item {
        i { display: inline-block !important; }
        &.strong {
            font-weight: 700;
            a {
                color: $yellowdark;
            }
        }
        a {
            color: $black;
            font-family: $font-family-secondary;
            margin-left: 15px;
        }
    }
    // for displaying list of products in a dropdown
    .product-card {
        img {
            width: clamp(30px, 10vw, var(--product-logo-size));
            height: clamp(30px, 10vw, var(--product-logo-size));
            // prevent alt tag from breaking layout in case of missing img
            max-width: var(--product-logo-size);
            white-space: nowrap;
            overflow: hidden;
        }
        .name {
            font-weight: 400;
            font-size: 16px;
            height: auto !important;
            overflow: visible;
        }
        @media(max-width: map-get($grid-breakpoints, "md")) {
            .d-flex { flex-wrap: nowrap; }
            div[class^="col"] {
                width: auto;
                &.details {
                    padding-left: 1em;
                    text-align: left;
                    align-items: start;
                    margin-top: 0;
                    > * {
                        height: auto;
                        margin: 0;
                    }
                }
            }
        }
    }
}

.dropdown-menu-right {
    right: 0!important;
    left: auto!important;
}

.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=top],
.dropdown-menu[x-placement^=left]{
    top: auto !important;
    animation: none !important;
}

@keyframes DropDownSlide {
    100% {
        transform: translateY(0)
    }
    0% {
        transform: translateY(40px)
    }
}


// Dropdown Large (Custom)

@media (min-width: 600px) {
    .dropdown-lg {
        width: $dropdown-lg-width;
    }
}

// Dropdown Mega Menu

.dropdown-mega {
    position: static!important;
}

.dropdown-megamenu {
    padding: 20px;
    left: 20px !important;
    right: 20px !important;
    //background-image: url("../images/megamenu-bg.png");
    //background-position: right bottom;
    //background-repeat: no-repeat;
}

.megamenu-list {
    li {
        padding: 5px 20px 5px 25px;
        position: relative;

        a {
            color: $dropdown-link-color;

            &:hover {
                color: $primary;
            }
        }

        &:before {
            content: "\F0142";
            position: absolute;
            left: 0;
            font-family:"Material Design Icons";
        }
    }
}

// Dropdown with Icons
.dropdown-icon-item {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    display: block;
    border: 1px solid transparent;
    color: $dropdown-link-color;

    img {
        height: 24px;
    }

    span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:hover {
        background-color: $dropdown-link-hover-bg;
    }
}

// Dropdown size
@include media-breakpoint-up(lg) {
    .dropdown-mega-menu-xl{
        width: 40rem;
    }
    
    .dropdown-mega-menu-lg{
        width: 26rem;
    }
}