//
// helper.scss
//

// Minimum width

.width-xs {
    min-width: 80px;
}

.width-sm {
    min-width: 100px;
}

.width-md {
    min-width: 120px;
}

.width-lg {
    min-width: 140px;
}

.width-xl {
    min-width: 160px;
}


// Font Family
.font-family-primary {
    font-family: $font-family-base;
}

.font-family-secondary {
    font-family: $font-family-secondary;
}

// Text specify lines (Only chrome browser support)

.sp-line-1,
.sp-line-2,
.sp-line-3,
.sp-line-4,
.sp-line-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.sp-line-1 {
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
}

.sp-line-2 {
    -webkit-line-clamp: 2 !important;
    line-clamp: 2 !important;
}


.sp-line-3 {
    -webkit-line-clamp: 3 !important;
    line-clamp: 3 !important;
}

.sp-line-4 {
    -webkit-line-clamp: 4 !important;
    line-clamp: 4 !important;
}

.sp-line-5 {
    -webkit-line-clamp: 5 !important;
    line-clamp: 5 !important;
}

// Icons
.icon-dual {
    color: $text-muted;
    fill: rgba($text-muted, 0.12);
}

@each $color,
$value in $theme-colors {
    .icon-dual-#{$color} {
        color: $value;
        fill: rgba($value, 0.16);
    }
}

.icons-xs {
    height: 16px;
    width: 16px;
}

.icons-sm {
    height: 24px;
    width: 24px;
}

.icons-md {
    height: 32px;
    width: 32px;
}

.icons-lg {
    height: 40px;
    width: 40px;
}

.icons-xl {
    height: 48px;
    width: 48px;
}

.icons-xxl {
    height: 60px;
    width: 60px;
}

// Hover item
.item-hovered {
    &:hover {
        background-color: $dropdown-link-hover-bg;
    }
}

@each $index, $value in $grays {
    .gray-#{$index} {
        color: $value;
    }
    .bg-gray-#{$index} {
        background: $value;
    }
}

// hover classes and text classes (where needed)
@each $color, $value in $theme-colors {
    .hover-bg-#{$color}:hover {
        background: $value;
    }
    .hover-bg-soft-#{$color}:hover {
        background-color: rgba(($value), 0.06) !important;
    }
    .hover-#{$color}:hover {
        color: $value;
    }
    .hover-overlay-#{$color} {
        --hover-overlay-opacity: 1;
        position: relative;
        z-index: 1;
        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 2;
            content: ' ';
        }
        &:hover:after {
            background: $value;
            opacity: var(--hover-overlay-opacity);
            cursor: pointer;
        }
    }
    .link-#{$color} {
        &:not(.disabled) { cursor: pointer; }
    }
}
@each $color, $value in $colors {
    .text-#{$color} {
        color: $value !important;
    }
    .bg-#{$color} {
        background-color: $value !important;
    }
    .hover-bg-#{$color}:hover {
        background: $value;
    }
    .hover-#{$color}:hover {
        color: $value !important;
    }

    .link-#{$color} {
        &:not(.disabled) { cursor: pointer; }
    }

    .#{$color}-links {
        a {
            color: $value;
            text-decoration: underline;
            &:hover {
                color: darken($value, 10%);
            }
            &:focus-visible { outline: none; }
        }
    }
}

@each $brand, $color in $social-colors {
    .text-#{$brand} {
        color: $color;
        &:hover {
            color: darken($color, 5%);
        }
    }
}

@each $color, $value in $theme-colors {
    // to style links within a block of text
    .#{$color}-links {
        a {
            color: $value;
            text-decoration: underline;
            &:hover {
                color: darken($value, 10%);
            }
            &:focus-visible { outline: none; }
        }
    }
}

.text-primary {
    color: $primary-text !important;
    &:hover {
        color: darken($primary-text, 5%) !important;
    }
}

.hover-bold {
    &:hover {
        font-weight: bold;
    }
}

a {
    &:not(.disabled) { cursor: pointer; }
    &.disabled {
        pointer-events: none;
        cursor: text;
    }
    &.link-as-text {
        color: inherit;
        text-decoration: none;
        &:hover { background: inherit; }
    }
    &.text-secondary {
        text-decoration: underline;
        text-decoration-color: $primary;
        text-decoration-thickness: 2px;
        text-underline-offset: 3px;
        &:hover { background: $primary; }
    }
}

/* border-between styles */
.border {
    &-between {
        @include border-between;
    }
    @each $breakpoint in map-keys($grid-breakpoints) {
        &-#{$breakpoint}-between {
            @media(min-width: map-get($grid-breakpoints, $breakpoint)) {
                @include border-between;
            }
        }
    }
}

.overflow {
    &-x-auto {
        overflow-x: auto;
    }
    &-y-auto {
        overflow-y: auto;
    }
}

.text {
    &-top {
        & > * { vertical-align: text-top; }
    }
    &-bottom {
        & > * { vertical-align: text-bottom; }
    }
}

/* changes for mobile */
@media(max-width: map-get($grid-breakpoints, "md")) {
    /* A multi-column layout of lists on desktop should appear as 1 list on mobile */
  .row {
      [class*='col-md-']:has(> .list-group) {
          &:not(:last-child) .list-group {
              > .list-group-item {
                  &:last-child {
                      border-bottom-width: 1px;
                  }
              }
          }
      }
  }
}

.shadow-bottom {
    box-shadow: $box-shadow-bottom;
}