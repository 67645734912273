// 
// accordions.scss
//

@import '../mixins';

.accordion {
    // scss-docs-start accordion-css-vars
    --#{$prefix}accordion-color: #{$accordion-color};
    --#{$prefix}accordion-bg: #{$accordion-bg};
    --#{$prefix}accordion-btn-color: #{$accordion-button-color};
    --#{$prefix}accordion-btn-bg: #{$accordion-button-bg};
    --#{$prefix}accordion-btn-focus-border-color: #{$accordion-button-focus-border-color};
    --#{$prefix}accordion-active-color: #{$accordion-button-active-color};
    --#{$prefix}accordion-active-bg: #{$accordion-button-active-bg};
    // scss-docs-end accordion-css-vars
}
.accordion-item {
    border: none;
    border-bottom: 1px solid $gray-400;
    box-sizing: border-box;
    position: relative;
    color: var(--#{$prefix}accordion-color);
    background-color: var(--#{$prefix}accordion-bg);
    &:hover {
        background-color: $gray-100;
    }
    &:last-child {
        border-bottom: 0px;
    }
    .accordion-toggle {
        padding: 20px 30px 20px 12px;
        color: $secondary;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.4;
        cursor: pointer;
        a { color: $secondary; }
        position: relative;
        &.collapsed {
            &:hover {
                &:after {
                    font-family: 'Font Awesome 6 Pro';
                    font-weight: 200;
                    content: '\f078';
                    color: $gray-400;
                    font-size: 20px;
                    position: absolute;
                    right: 10px;
                    top: 31%;
                    transform: rotate(-360deg);
                    transition: transform 0.5s;
                }
            }
            &.with_icon {
                &:after {
                    font-family: 'Font Awesome 6 Pro';
                    font-weight: 200;
                    content: '\f078';
                    color: $gray-400;
                    font-size: 20px;
                    position: absolute;
                    right: 10px;
                    top: 31%;
                    transform: rotate(-360deg);
                    transition: transform 0.5s;
                }
            }
        }
        &:not(.collapsed) {
            &:after {
                font-family: 'Font Awesome 6 Pro';
                font-weight: 200;
                content: '\f078';
                color: $gray-400;
                font-size: 20px;
                position: absolute;
                right: 10px;
                top: 31%;
                transform: rotate(-180deg);
                transition: transform 0.5s;
            }
        }
    }
}

.collapse-toggle-control {
    font-family: $font-family-secondary;
    position: relative;
    @include branded-link-styling(2px);
    &[aria-expanded="false"] {
        &:after {
            content: attr(data-more);
            font-weight: 600;
        }
        & i {
            &:after {
                font-family: 'Font Awesome 6 Pro';
                font-weight: 600;
                content: '\f107';
                color: $secondary;
                position: absolute;
                top: 50%;
                right: -20px;
                transform: translateY(-50%);
            }
        }
    }
    &[aria-expanded="true"] {
        &:after {
            font-weight: 600;
            content: attr(data-less);
        }
        & i {
            &:after {
                font-family: 'Font Awesome 6 Pro';
                font-weight: 600;
                content: '\f106';
                color: $secondary;
                position: absolute;
                top: 50%;
                right: -20px;
                transform: translateY(-50%);
            }
        }
    }
}

.accordion-button {
    font-size: 16px;
    &:not(.collapsed) {
        color: var(--#{$prefix}accordion-active-color);
        background-color: var(--#{$prefix}accordion-active-bg);
    }
}

.top-level {
    font-family: $font-family-secondary;
    font-weight: 800;
    font-size: 26px;
    padding: 15px 0;
    letter-spacing: 0.01em;
    color: $secondary;
    @include branded-top-bar(3px);
    @media(max-width: map-get($grid-breakpoints, "md")) {
        margin-top: 30px;
    }
}
