
//
// Premium font - Martel
//

@font-face {
    font-family: 'Martel';
    src: url(../../fonts/Martel-ExtraLight.ttf);
    font-weight: 200;
    font-display: swap;
}
@font-face {
    font-family: 'Martel';
    src: url(../../fonts/Martel-Light.ttf);
    font-weight: 300;
    font-display: swap;
}
@font-face {
    font-family: 'Martel';
    src: url(../../fonts/Martel-Regular.ttf);
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Martel';
    src: url(../../fonts/Martel-SemiBold.ttf);
    font-weight: 600;
    font-display: swap;
}
@font-face {
    font-family: 'Martel';
    src: url(../../fonts/Martel-Bold.ttf);
    font-weight: 700;
    font-display: swap;
}
@font-face {
    font-family: 'Martel';
    src: url(../../fonts/Martel-ExtraBold.ttf);
    font-weight: 800;
    font-display: swap;
}
@font-face {
    font-family: 'Martel';
    src: url(../../fonts/Martel-Black.ttf);
    font-weight: 900;
    font-display: swap;
}

//
// Premium font - Lato
//

@font-face {
    font-family: 'Lato';
    src: url(../../fonts/Lato-Thin.ttf);
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url(../../fonts/Lato-ThinItalic.ttf);
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url(../../fonts/Lato-Light.ttf);
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url(../../fonts/Lato-LightItalic.ttf);
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url(../../fonts/Lato-Regular.ttf);
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url(../../fonts/Lato-Bold.ttf);
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url(../../fonts/Lato-BoldItalic.ttf);
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url(../../fonts/Lato-Black.ttf);
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url(../../fonts/Lato-BlackItalic.ttf);
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}
