
// 
// breadcrumb.scss
//

.breadcrumb {
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-bottom: 0;

    // Breadcrumb item arrow
    .breadcrumb-item {
        flex: 0 0 auto;
        a {
            color: $secondary;
            font-weight: 700;
            &:hover {
                background: $primary;
            }
        }
        &.active {
            color: $gray-700;
        }
        +.breadcrumb-item {
            &::before {
                font-weight: 900;
                content: '/';
                color: $gray-700;
                font-size: 11px;
                float: none;
            }
        }
    }
}
  